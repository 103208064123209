// React imports
import React, { useEffect } from "react";
import { useState } from "react";

// API imports
import {
  Tag,
  useGetCaseCasesCaseIdGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { AddCircle, Cancel } from "@mui/icons-material";

// Local imports
import TagChip from "./Tag";
import Spacer from "../navbar/appBar/Spacer";
import Loader from "../loaders/Loader";
import TagManager from "./TagManager";

// Interface
interface AddTagsProps {
  tags: Tag[];
  caseId: string;
  setTags: (tags: Tag[]) => void;
}

// Component
const AddTags: React.FC<AddTagsProps> = ({ tags, caseId, setTags }) => {
  const [showTags, setShowTags] = useState(false);

  const [caseTags, setCaseTags] = useState<Tag[]>([]);

  const {
    data: caseData,
    error: caseError,
    isLoading: isCaseLoading,
    // refetch: refetchCase,
  } = useGetCaseCasesCaseIdGetQuery(
    { caseId: caseId || "" },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (caseData) {
      setCaseTags(caseData.tags ?? []);
      console.log("Case tags: ", caseData.tags);
    }
  }, [caseData]);

  const handleRemoveTag = (tagId: string) => {
    console.log("Tag id: ", tagId);
    const removedTag = tags.find((tag) => tag.id === tagId);
    if (removedTag) {
      setTags(tags.filter((tag) => tag.id !== tagId));
      // setCaseTags([...caseTags, removedTag]);
    }
  };

  const handleAddTag = (tag: Tag) => {
    if (!tags.some((existingTag) => existingTag.id === tag.id)) {
      console.log("Tag: ", tag);
      setTags([...tags, tag]);
      // setCaseTags(caseTags.filter((caseTag) => caseTag.id !== tag.id));
    } else {
      console.log("Duplicate tag: ", tag);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          flexWrap: "wrap",
          alignItems: "center",
          mt: 3,
          p: 2,
          border: "1px solid #E0E0E0",
          borderRadius: "5px",
        }}
      >
        {tags.map((tag) => (
          <Box key={tag.id} onClick={() => tag.id && handleRemoveTag(tag.id)}>
            <TagChip
              key={tag.id}
              id={Number(tag.id)}
              color={tag.color ?? ""}
              name={tag.name ?? ""}
              icon={<Cancel />}
            />
          </Box>
        ))}
        <Button
          onClick={() => setShowTags(true)}
          size="small"
          endIcon={<AddCircle />}
        >
          Add Tag
        </Button>
      </Stack>
      <Collapse in={showTags}>
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            gap: 2,
            alignItems: "center",
            mt: 3,
            p: 2,
            border: "1px solid #E0E0E0",
            borderRadius: "5px",
          }}
        >
          {caseError ? (
            <Box>
              <Typography variant="body1" color="error">
                Error fetching tags
              </Typography>
            </Box>
          ) : null}
          {isCaseLoading ? <Loader /> : null}
          {caseTags.map((tag) => (
            <Box key={tag.id} onClick={() => handleAddTag(tag)}>
              <TagChip
                id={Number(tag.id)}
                color={tag.color ?? ""}
                name={tag.name ?? ""}
                icon={<AddCircle />}
              />
            </Box>
          ))}
          <Spacer />
          <Button
            size="small"
            title="Close"
            onClick={() => setShowTags(false)}
            sx={{ color: "#9b9b9b" }}
          >
            Close
          </Button>
          <TagManager iconButton={true} caseId={caseId} />
        </Stack>
      </Collapse>
    </Box>
  );
};

export default AddTags;
