// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Local imports

// import GetTimeline from "../../../components/apiTesting/Timeline/GetTimeline";
import TimelineTable from "../../../components/timeline/TimelineTable";

const SelectedPatientTimelinePanel: React.FC = () => {
  const selectedCharacter = useParams<{ patientId: string }>().patientId;

  return (
    <div>
      <TimelineTable characterId={selectedCharacter || ""} />
      {/* <GetTimeline characterId={selectedCharacter || ""} /> */}
    </div>
  );
};

export default SelectedPatientTimelinePanel;
