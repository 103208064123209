// React imports
import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

// Redux imports
// import { useSelector } from "react-redux";
// import { RootState } from "../../redux/store";

// MUI imports
import { Box } from "@mui/material";

// API imports
import { useGetDocumentFilesFileIdGetQuery } from "../../api/jabsCentralApiPrivate";

//Local imports
import Loader from "../../components/loaders/Loader";
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";

const FullPageViewerPage: React.FC = () => {
  const selectedFile = useParams<{ fileId: string }>().fileId || "";
  const {
    data: fileData,
    error: fileError,
    isLoading: isFileLoading,
    isFetching: isFileFetching,
    // refetch: refetchFile,
  } = useGetDocumentFilesFileIdGetQuery({
    fileId: selectedFile,
    // fileId: "67add49f336d78dfad069f04",
  });

  if (isFileLoading || isFileFetching) {
    return <Loader />;
  }

  if (fileError) {
    return <div>Error loading file data.</div>;
  }

  return (
    <Box sx={{ height: "calc(100vh - 130px) ", width: "100%" }}>
      <SidelightPageBar title={fileData?.filename || "File"} />
      <Helmet title={fileData?.filename || "File"} />
      {fileData?.url && (
        <embed
          src={fileData.url}
          width="100%"
          height="100%"
          aria-label="pdf viewer"
          type={fileData.type}
        />
      )}
    </Box>
  );
};

export default FullPageViewerPage;
