import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { apiConfig, auth0Config } from "../config";

let auth0Client: any = null;

const getToken = async () => {
  const key = `@@auth0spajs@@::${auth0Config.clientId}::${auth0Config.apiAudience}::`;
  for (let i = 0; i < localStorage.length; i++) {
    const k = localStorage.key(i);
    if (k && k.startsWith(key)) {
      const tokenObject = JSON.parse(localStorage.getItem(k)!);
      return tokenObject.body.access_token;
    }
  }
  return "";
};

const baseQuery = fetchBaseQuery({
  baseUrl: apiConfig.privateUrl,
  prepareHeaders: async (headers) => {
    headers.set("x-api-key", apiConfig.privateApiKey!);
    const token = await getToken();
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && (result.error as FetchBaseQueryError).status === 401) {
    // Try to get a new token
    try {
      if (!auth0Client) {
        const { Auth0Client } = await import("@auth0/auth0-spa-js");
        auth0Client = new Auth0Client({
          clientId: auth0Config.clientId || "",
          domain: auth0Config.domain || "",
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: auth0Config.apiAudience,
            scope: `${auth0Config.apiScope} offline_access`,
          },
          useRefreshTokens: true,
          cacheLocation: "localstorage",
        });
      }

      // Force token refresh
      await auth0Client.getTokenSilently({ cacheMode: "off" });

      // Get new token and retry the request
      const token = await getToken();
      if (token) {
        // Retry the initial query with new token
        result = await baseQuery(args, api, extraOptions);
      }
    } catch (error) {
      console.error("Token refresh failed:", error);
      // If refresh fails, we might need to redirect to login
      window.location.href = "/auth/sign-in";
    }
  }
  return result;
};

export const jabsCentralApiPrivateBase = createApi({
  reducerPath: "jabsCentralApiPrivate",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
