// react imports
import React from "react";
import { useEffect, useState } from "react";

// Redux imports
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

// MUI imports
import { Box } from "@mui/material";
import {
  DataGridPro,
  // gridClasses,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

//API imports
import {
  Character,
  useListCaseCharactersCasesCaseIdCharactersGetQuery,
} from "../../api/jabsCentralApiPrivate";

// Local imports
import Spacer from "../navbar/appBar/Spacer";
import AddCharacterButton from "./AddCharacterButton";

// Props interface for the character table component
interface CharacterTableProps {
  caseId: string;
}

const CharacterTable: React.FC<CharacterTableProps> = ({ caseId }) => {
  // Custom toolbar component for the character table
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ pt: 2, pb: 2, borderBottom: "1px solid #E0E0E0" }}
      >
        <AddCharacterButton caseId={caseId} />
        <Spacer />
        <GridToolbarQuickFilter sx={{ mr: "10px" }} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <GridToolbarExport
            slotProps={{
              button: { variant: "outlined" },
            }}
          />
          <GridToolbarFilterButton
            slotProps={{
              button: { variant: "outlined" },
            }}
          />
        </Box>
      </GridToolbarContainer>
    );
  };

  const updatedCharacter = useSelector(
    (state: RootState) => state.updatedCharacters
  );
  const [rowData, setRowData] = useState<Character[]>([]);
  // Fetch list of characters
  const {
    data: listCharacters,
    error: listCharactersError,
    isLoading: isListCharactersLoading,
    isFetching: isListCharactersFetching,
    refetch: refetchListCharacters,
  } = useListCaseCharactersCasesCaseIdCharactersGetQuery({
    caseId: caseId,
    limit: 1000,
  });

  useEffect(() => {
    refetchListCharacters();
    setRowData(listCharacters?.data || []);
  }, [updatedCharacter, refetchListCharacters, listCharacters?.data]);

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log("params: ", params);
  };

  return (
    <React.Fragment>
      {listCharactersError && <div>{JSON.stringify(listCharactersError)}</div>}
      <DataGridPro
        loading={isListCharactersLoading || isListCharactersFetching}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
        onRowClick={handleRowClick}
        checkboxSelection
        disableRowSelectionOnClick // Disable row selection on click
        columnHeaderHeight={40}
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          pt: 1,
          border: "none",
          backgroundColor: "white",
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          sorting: { sortModel: [{ field: "createdAt", sort: "asc" }] }, // Set the sorting column
          density: "standard", // Set the density to standard
        }}
        rows={rowData} // Set the rows of the DataGridPro to the list of characters
        columns={[
          { field: "first_name", headerName: "First Name", flex: 1 },
          { field: "last_name", headerName: "Last Name", flex: 1 },
          {
            field: "date_of_birth",
            headerName: "Date of Birth",
            flex: 1,
            type: "date",
            renderCell: (params) => (
              <span>
                {params.value
                  ? new Date(params.value).toLocaleDateString()
                  : null}
              </span>
            ),
            valueGetter: (params) => {
              return params ? new Date(params) : null;
            },
          },
          { field: "character_type", headerName: " Type", flex: 1 },
        ]}
      />
    </React.Fragment> // Set the columns for the DataGridPro
  );
};

export default CharacterTable;
