// React imports
import React from "react";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";

// API imports - !fake data!
// import caseListData from "../../mockData/sidelight/caseData";
// import { CaseData } from "../../mockData/sidelight/caseData";
import {
  Case,
  useListCasesCasesGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Autocomplete, Skeleton, TextField } from "@mui/material";

function CaseSelector() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Selector to get the updated cases from the Redux store
  const updatedCases = useSelector((state: RootState) => state.updatedCases);
  //Get the selected case from the Redux store
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  // Get the selected case from the URL
  const selectedCaseParams = useParams<{ caseId: string }>().caseId;

  var selectedCase = selectedCaseRedux; //default to Redux value
  //if the URL has a value, update the selectedCase to match the URL value
  if (selectedCaseParams) {
    dispatch(setSelectedCase(selectedCaseParams)); //update Redux value
    selectedCase = selectedCaseParams; //update selectedCase to the URL value
  }
  // !fake data!
  // const caseDetails = caseListData.find((c) => c.case_id === selectedCase);
  const [caseOption, setCaseOption] = useState<Case | null>(null);

  const handleSelection = (event: any, newValue: Case | null) => {
    setCaseOption(newValue);
    // dispatch(setSelectedCase(newValue ? newValue.id : ""));
    if (newValue) {
      navigate(`/case/${newValue.id}`);
    }
  };

  //data imports
  let content = null;
  const {
    data: listCases,
    error: listCasesError,
    isLoading: isListCasesLoading,
    isFetching: isListCasesFetching,
    refetch: refetchListCases,
  } = useListCasesCasesGetQuery({
    status: "active",
  });

  useEffect(() => {
    if (listCases) {
      setCaseOption(listCases.data?.find((c) => c.id === selectedCase) ?? null);
    }
  }, [listCases, selectedCase]);
  useEffect(() => {
    refetchListCases();
  }, [updatedCases, refetchListCases]);

  if (isListCasesLoading || isListCasesFetching) {
    content = <Skeleton variant="rectangular" width={300} height={40} />;
  } else if (listCasesError) {
    content = <div>{JSON.stringify(listCasesError)}</div>;
  } else {
    if (!listCases) {
      content = <div>!Listcases</div>;
    } else {
      content = (
        <Autocomplete
          id="case-selector"
          value={caseOption}
          options={listCases.data ?? []}
          onChange={handleSelection}
          getOptionLabel={(option) => option.title ?? ""}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ mt: 1 }}
              size="small"
              label="Case"
              variant="outlined"
            />
          )}
        />
      );
    }
  }

  // console.log("caseOption: ", caseOption);

  return <React.Fragment>{content}</React.Fragment>;
}

export default CaseSelector;
