import React from "react";
import styled from "@emotion/styled";
// import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Avatar,
  IconButton as MuiIconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import useAuth from "../../hooks/useAuth";
import { usePermissions } from "../../hooks/usePermissions";
import { PermissionGuard } from "../PermissionGuard";
import { Permissions } from "../../constants/permissions";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();
  const { signOut, user } = useAuth();
  const permissions = usePermissions().getPermissions();
  console.log("User permissions:", permissions);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          {user && <Avatar alt={user.displayName} src={user.avatar} />}
          {/* Demo data */}
          {!user && (
            <Avatar alt="Guest user" src="/static/img/avatars/guest-user.jpg" />
          )}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        <ListSubheader>Permissions</ListSubheader>
        {/* Add additional menu items based on user permissions */}
        <PermissionGuard requiredPermission={Permissions.USERS_READ}>
          <MenuItem onClick={closeMenu}>Users Read</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.USERS_MANAGE}>
          <MenuItem onClick={closeMenu}>Users Manage</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.CASE_READ}>
          <MenuItem onClick={closeMenu}>Case Read</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.CASE_MANAGE}>
          <MenuItem onClick={closeMenu}>Case Manage</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.DOCUMENT_READ}>
          <MenuItem onClick={closeMenu}>Document Read</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.DOCUMENT_UPLOAD}>
          <MenuItem onClick={closeMenu}>Document Upload</MenuItem>
        </PermissionGuard>
        <PermissionGuard requiredPermission={Permissions.DOCUMENT_MANAGE}>
          <MenuItem onClick={closeMenu}>Document Manage</MenuItem>
        </PermissionGuard>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
