// React imports
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
// import { setSelectedChronology } from "../../redux/slices/selectedChronologySlice";

// API imports
import {
  Chronology,
  ChronologyEvent2,
  useGetChronologyCasesCaseIdChronologyChronologyIdGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Box, Stack, Typography } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

// Component imports
import ChronologySelector from "./ChronologySelector";
import AddChronologyButton from "./AddChronologyButton";
import Markdown from "react-markdown";
import Spacer from "../navbar/appBar/Spacer";
import Tag from "../tags/Tag";
import ViewEditEventForm from "./eventComponents/ViewEditEventForm";

// custom box for aligning cell content vertically
const CentralVertical = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
`;

const CardContainer = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.paper,
  padding: "0px 12px 0 12px",
  height: "calc(100vh - 200px)",
}));

interface ChronologyTableProps {
  caseId: string;
}

const ChronologyTable: React.FC<ChronologyTableProps> = ({ caseId }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ChronologyEvent2 | null>(
    null
  );
  const updatedEvent = useSelector((state: RootState) => state.updatedEvents);
  const selectedPatient = useParams<{ patientId: string }>().patientId;
  const [rowData, setRowData] = React.useState<Chronology[]>([]);

  const chronologyId = useSelector(
    (state: RootState) => state.chronologySelection.selectedItem
  );
  // Fetch chronology
  const {
    data: getChronology,
    error: getChronologyError,
    isLoading: isGetChronologyLoading,
    isFetching: isGetChronologyFetching,
    refetch: refetchGetChronology,
  } = useGetChronologyCasesCaseIdChronologyChronologyIdGetQuery({
    caseId: caseId,
    chronologyId: chronologyId || "",
  });

  useEffect(() => {
    refetchGetChronology();
    setRowData(getChronology?.events ?? []);
  }, [updatedEvent, refetchGetChronology, getChronology?.events, dispatch]);

  // Handle row click
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    setSelectedEvent(params.row as ChronologyEvent2);
    console.log("selectedEvent: ", selectedEvent);
    setOpen(true);
  };

  // Custom toolbar component for the character table
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ pt: 2, pb: 2, borderBottom: "1px solid #E0E0E0" }}
      >
        <Typography variant="h4">
          {getChronology?.name ?? "Loading..."}
        </Typography>
        <Spacer />
        <GridToolbarQuickFilter sx={{ mr: "10px" }} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <GridToolbarExport
            slotProps={{
              button: { variant: "outlined" },
            }}
          />
          <GridToolbarFilterButton
            slotProps={{
              button: { variant: "outlined" },
            }}
          />
          <ChronologySelector />
          <AddChronologyButton
            caseId={caseId || ""}
            patientId={selectedPatient || ""}
          />
        </Box>
      </GridToolbarContainer>
    );
  };

  return (
    <>
      {getChronologyError && <div>{JSON.stringify(getChronologyError)}</div>}
      <CardContainer>
        <DataGridPro
          checkboxSelection
          columns={[
            {
              field: "event_date",
              headerName: "Start date",
              width: 180,
              type: "date",
              renderCell: (params) => (
                <CentralVertical>
                  {params.value
                    ? new Date(params.value).toLocaleString()
                    : "N/A"}
                </CentralVertical>
              ),
              valueGetter: (params) => {
                return params ? new Date(params) : null;
              },
            },
            {
              field: "event_end",
              headerName: "End date",
              width: 180,
              type: "date",
              renderCell: (params) => (
                <CentralVertical>
                  {params.value
                    ? new Date(params.value).toLocaleString()
                    : "N/A"}
                </CentralVertical>
              ),
              valueGetter: (params) => {
                return params ? new Date(params) : null;
              },
            },
            {
              field: "event_type",
              headerName: "Type",
              width: 120,
              renderCell: (params) => (
                <CentralVertical>{params.value as string}</CentralVertical>
              ),
            },
            {
              field: "event_description",
              headerName: "Description",
              flex: 2,
              minWidth: 200,
              renderCell: (params) => (
                <CentralVertical>
                  <Box>
                    <Markdown>{params.value as string}</Markdown>
                  </Box>
                </CentralVertical>
              ),
            },
            {
              field: "characters",
              headerName: "Characters",
              flex: 1,
              minWidth: 150,

              renderCell: (params) => (
                <CentralVertical>{params.value as string}</CentralVertical>
              ),
            },
            {
              field: "tags",
              headerName: "Tags",
              width: 120,
              renderCell: (params) => {
                const { row } = params;
                return (
                  <CentralVertical>
                    <Stack spacing={1} sx={{ flexWrap: "wrap" }}>
                      {row.tags &&
                        row.tags.map((tag: any, index: number) => (
                          <Tag
                            key={index}
                            id={tag.id}
                            name={tag.name}
                            color={tag.color}
                            fullWidth={true}
                          />
                        ))}
                    </Stack>
                  </CentralVertical>
                );
              },
              valueGetter: (row: any[] | undefined) => {
                if (!row) {
                  return "";
                }
                return row.map((tag) => tag.name).join(", ");
              },
            },
            {
              field: "url",
              headerName: "Source",
              flex: 1,
              minWidth: 150,
              renderCell: (params) => (
                <CentralVertical>{params.value as string}</CentralVertical>
              ),
            },
          ]}
          disableRowSelectionOnClick // Disable row selection on click
          getRowHeight={() => "auto"} // Set the row height to auto
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            }, // Set the pinned columns
            sorting: { sortModel: [{ field: "start_date", sort: "desc" }] }, // Set the sorting column
            density: "standard", // Set the density to comfortable
          }}
          loading={isGetChronologyLoading || isGetChronologyFetching}
          onRowClick={handleRowClick}
          rows={rowData}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "skeleton",
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          sx={{
            pt: 1,
            border: "none",
            backgroundColor: "white",
            [`& .${gridClasses.cell}`]: {
              pt: 2,
              pb: 2,
              pr: 3,
              pl: 3,
            },
          }}
        />
      </CardContainer>
      <ViewEditEventForm
        open={open}
        onClose={() => setOpen(false)}
        eventData={selectedEvent || ({} as ChronologyEvent2)}
        caseId={caseId}
      />
    </>
  );
};

export default ChronologyTable;
