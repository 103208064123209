// Import necessary functions and types from Redux Toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for the state
interface SelectionChronologyId {
  selectedItem: string | null; // The ID of the selected chronology, or null if no chronology is selected
}

// Define the initial state using the interface
const initialState: SelectionChronologyId = {
  selectedItem: null, // Initially, no chronology is selected
};

// Create a slice for chronology selection
const selectedChronologySlice = createSlice({
  name: "chronologySelection", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducer to set the selected chronology
    setSelectedChronology(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload; // Set the selected chronology ID to the payload
    },
    // Reducer to clear the selected chronology
    clearSelectedChronology(state) {
      state.selectedItem = null; // Clear the selected chronology ID
    },
  },
});

// Export the actions generated by createSlice
export const { setSelectedChronology, clearSelectedChronology } =
  selectedChronologySlice.actions;

// Export the reducer to be used in the store
export default selectedChronologySlice.reducer;
