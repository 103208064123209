// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Redux imports
// import { useSelector } from "react-redux";
// import { RootState } from "../../../redux/store";

// Local imports

import ChronologyTable from "../../../components/chronology/ChronologyTable";

const SelectedPatientChronologyPanel: React.FC = () => {
  const selectedCase = useParams<{ caseId: string }>().caseId;

  return (
    <>
      <ChronologyTable caseId={selectedCase || ""} />
      {/* <Chronology caseId={selectedCase || ""} /> */}
    </>
  );
};

export default SelectedPatientChronologyPanel;
