// React imports
import React from "react";
import { useNavigate } from "react-router-dom";

// Redux imports
import { useDispatch } from "react-redux";
import { setUpdatedCases } from "../../redux/slices/updatedCasesSlice";

// MUI imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";

// API imports
import { useArchiveCaseCasesCaseIdDeleteMutation } from "../../api/jabsCentralApiPrivate";

// Interface for component props
interface DeleteCaseModalProps {
  caseId: string;
  title: string;
  open: boolean; // Indicates if the modal is open
  onClose: () => void; // Function to close the modal
}

// Component definition
const DeleteCaseModal: React.FC<DeleteCaseModalProps> = ({
  caseId,
  title,
  open,
  onClose,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [archiveCase] = useArchiveCaseCasesCaseIdDeleteMutation();

  const handleDelete = async () => {
    try {
      await archiveCase({ caseId });
      dispatch(setUpdatedCases(JSON.stringify(new Date())));
      onClose();
      navigate("/cases");
    } catch (error) {
      console.error("Failed to delete the case: ", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Delete ${title}?`}</DialogTitle>
      <DialogContent>This will remove the case from your system</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCaseModal;
