//React imports
import React from "react";
import { useEffect, useState, useRef } from "react";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setUpdatedEvent } from "../../../redux/slices/updatedEventSlice";

// react-pdf imports
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Pagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/system";

// API imports
import { useCreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostMutation } from "../../../api/jabsCentralApiPrivate";

// Local imports
import JoditEditor from "jodit-react";
import { wysiwygConfig } from "../../textEditor/wysiwygConfig";
import htmlToMarkdown from "../../../utils/htmlToMarkdown";
import ChronologySelector from "../ChronologySelector";

// required for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// Interface for component props
interface AddEventFormProps {
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
  caseId: string; // The case ID
  fileId: string; // The file ID
  src: string; // The file source
  fileName: string; // The file name
  pageNumber: number; // The page number
  chronologyId?: string; // Optional: The chronology ID (if needed for future use)
}

const DocumentContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: "flex",
  //   justifyContent: "center",
  alignItems: "center",
  height: "calc(100vh - 60px)",
  overflowY: "auto",
  paddingBottom: "20px",
  borderWidth: " 0 1px 0 0",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  flexDirection: "column",
}));

// Component definition
const AddEventForm: React.FC<AddEventFormProps> = ({
  open,
  onClose,
  caseId,
  fileId,
  src,
  fileName,
  pageNumber,
}) => {
  // Redux dispatch function
  const dispatch = useDispatch();
  const editor = useRef(null);

  const [createEvent] =
    useCreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostMutation(); // API call to create an event

  const chronologyid = useSelector(
    (state: RootState) => state.chronologySelection.selectedItem
  );
  const [numPages, setNumPages] = useState<number | undefined>(undefined); // State to store the number of pages in the PDF

  // State variables for form fields
  const [eventType, setEventType] = useState("");
  const [dateValues, setDateValues] = useState<DateRange<Date>>([null, null]);
  const [eventDescription, setEventDescription] = useState("");
  const [pageReference, setPageReference] = useState(pageNumber);

  useEffect(() => {
    setPageReference(pageNumber);
  }, [pageNumber]);

  // Function to reset form fields
  const resetFormFields = () => {
    setPageReference(pageNumber);
    setEventDescription("");
    setDateValues([null, null]);
    setEventType("");
  };

  // Function to handle form close
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle form save
  const handleSave = async () => {
    const eventDescriptionMarkdown = htmlToMarkdown(eventDescription);
    const strPageNumber = String(pageReference);
    console.log("page number: ", strPageNumber);

    const eventDate = dateValues[0]?.toISOString();
    const eventDateEnd = dateValues[1]?.toISOString();

    try {
      await createEvent({
        caseId: caseId,
        chronologyId: chronologyid || "",
        chronologyEventInput: {
          file_id: fileId,
          event_description: eventDescriptionMarkdown,
          event_date: eventDate, // Start date
          event_date_end: eventDateEnd, // End date
          event_type: eventType,
          location: src,
          page_reference: strPageNumber,
        },
      });

      dispatch(setUpdatedEvent(JSON.stringify(new Date())));
    } catch (error) {
      console.error("Error creating event: ", error);
    }
    handleClose();
  };

  // Callback function to handle successful document load
  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    console.log(`Document loaded with ${numPages} pages`);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "1200px" } }}
    >
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Add Event: {fileName}
          </Typography>
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Add
          </Button>
        </Toolbar>
      </AppBar>
      <Grid2 container>
        <Grid2 xs={6}>
          <DocumentContainer>
            <Pagination
              count={numPages}
              page={pageReference}
              onChange={(event, value) => setPageReference(value)}
              sx={{ pt: 3, pb: 1 }}
              color="primary"
            />
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "2px",
                padding: "1px",
                width: 560,
              }}
            >
              <Document
                file={src}
                onLoadSuccess={onDocLoadSuccess}
                onItemClick={() => {}}
              >
                <Page
                  width={550}
                  pageNumber={pageReference}
                  renderTextLayer={false} // Disable text layer
                  renderAnnotationLayer={false} // Disable annotation layer
                />
              </Document>
            </Box>
          </DocumentContainer>
        </Grid2>
        <Grid2 xs={6}>
          <Stack useFlexGap spacing={6} sx={{ p: 6 }}>
            <ChronologySelector />
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Box>
                <FormControl sx={{ width: "150px" }}>
                  <InputLabel id="type">Type</InputLabel>

                  <Select
                    id="type"
                    label="Type"
                    value={eventType}
                    onChange={(event: SelectChangeEvent) => {
                      setEventType(event.target.value as string);
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="Note">Note</MenuItem>
                    <MenuItem value="Meeting">Meeting</MenuItem>
                    <MenuItem value="Consultation">Consultation</MenuItem>
                    <MenuItem value="Observation">Observation</MenuItem>
                    <MenuItem value="Appointment">Appointment</MenuItem>
                    <MenuItem value="Procedure">Procedure</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enGB}
                >
                  <DateTimeRangePicker
                    localeText={{ start: "Event start", end: "Event end" }}
                    value={dateValues}
                    onChange={(newValue) => {
                      setDateValues(newValue);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Stack>
            <Box>
              <Typography
                variant="overline"
                color="GrayText"
                sx={{ lineHeight: 1, textTransform: "capitalize", ml: 3 }}
              >
                Description
              </Typography>
              <JoditEditor
                ref={editor}
                value={eventDescription}
                config={wysiwygConfig}
                tabIndex={1}
                onBlur={(newContent) => setEventDescription(newContent)}
              />
            </Box>

            <TextField
              label="Page Reference"
              value={pageReference}
              onChange={(e) => setPageReference(Number(e.target.value))}
              fullWidth
            />
          </Stack>
        </Grid2>
      </Grid2>
    </Drawer>
  );
};

export default AddEventForm;
