// React imports
import React from "react";
import { useState, useRef } from "react";

// Redux imports
import { useDispatch } from "react-redux";
import { setUpdatedEvent } from "../../redux/slices/updatedEventSlice";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

// API imports
import { useCreateChronologyCasesCaseIdChronologyPostMutation } from "../../api/jabsCentralApiPrivate";

// Local imports
import JoditEditor from "jodit-react";
import { wysiwygConfig } from "../textEditor/wysiwygConfig";
import htmlToMarkdown from "../../utils/htmlToMarkdown";

// Interface for component props
interface AddChronologyFormProps {
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
  caseId: string; // The case ID

  patientId: string;
}

// Component definition
const AddChronologyForm: React.FC<AddChronologyFormProps> = ({
  open,
  onClose,
  caseId,
  patientId,
}) => {
  // Redux dispatch function
  const dispatch = useDispatch();
  const editor = useRef(null);

  const [createChronology] =
    useCreateChronologyCasesCaseIdChronologyPostMutation(); // API call to create a chronology

  // State variables for form fields
  const [chronologyName, setChronologyName] = useState("");
  const [chronologyDescription, setChronologyDescription] = useState("");
  const [chronologyStatus, setChronologyStatus] = useState("");

  // Function to reset form fields
  const resetFormFields = () => {
    setChronologyName("");
    setChronologyDescription("");
    setChronologyStatus("");
  };

  // Function to handle form close
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle form save
  const handleSave = async () => {
    const chronolgyDescriptionMarkdown = htmlToMarkdown(chronologyDescription);
    const newChronology = {
      name: chronologyName,
      description: chronolgyDescriptionMarkdown,
      status: chronologyStatus,
      characters: [patientId],
    };
    try {
      await createChronology({
        caseId,
        createChronologyRequest: newChronology,
      });
      dispatch(setUpdatedEvent(JSON.stringify(new Date())));
    } catch (error) {
      console.error("Error adding chronology: ", error);
    }
    handleClose();
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
            Add Chronology
          </Typography>
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Add
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 610, py: 6, px: 8 }}>
        <Stack spacing={4}>
          <TextField
            label="Chronology Name"
            variant="outlined"
            value={chronologyName}
            onChange={(e) => setChronologyName(e.target.value)}
          />
          <Box>
            <Typography
              variant="overline"
              color="GrayText"
              sx={{ lineHeight: 1, textTransform: "capitalize", ml: 3 }}
            >
              Description
            </Typography>
            <JoditEditor
              ref={editor}
              config={wysiwygConfig}
              value={chronologyDescription}
              tabIndex={1}
              onBlur={(newContent) => setChronologyDescription(newContent)}
            />
          </Box>
          <FormControl variant="outlined">
            <InputLabel id="chronology-status-label">Status</InputLabel>
            <Select
              labelId="chronology-status-label"
              value={chronologyStatus}
              onChange={(e: SelectChangeEvent) =>
                setChronologyStatus(e.target.value as string)
              }
              label="Status"
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default AddChronologyForm;
