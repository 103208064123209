// React imports
import React from "react";

// Material-UI imports
import { Box, Card, Typography } from "@mui/material";

//API imports
import { Case } from "../../api/jabsCentralApiPrivate";

// Component imports
import CardTitle from "../Card/CardTitle";
import CardInfoItem from "../Card/CardInfoItem";
import Markdown from "react-markdown";

// CSS imports
import "../../components/textEditor/markdown.css";

interface CaseDetailsCardProps {
  caseData: Case;
}

const CaseDetailsCard: React.FC<CaseDetailsCardProps> = ({ caseData }) => {
  return (
    <Card sx={{ maxWidth: "440px", mb: 5 }}>
      <CardTitle title="Case" />
      <Box sx={{ px: 5, py: 7 }}>
        {/* Case Id */}
        <CardInfoItem label="Case Id" value={caseData.id} />
        {/* Title */}
        <CardInfoItem label="Title" value={caseData.title} />
        {/* Date */}
        <CardInfoItem label="Start Date" value={caseData.start_date} />
        {/* Type */}
        <CardInfoItem label="Type" value={caseData.case_type} />
        {/* Status */}
        <CardInfoItem label="Status" value={caseData.case_status} />
        {/* Description */}
        <Box sx={{ display: "flex", pb: 2 }}>
          <Box sx={{ width: 110, flexShrink: 0, pr: 2 }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: 700, textAlign: "right" }}
            >
              Description:
            </Typography>
          </Box>
          <Box>
            <Markdown>{caseData.description}</Markdown>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CaseDetailsCard;
