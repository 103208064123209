// React imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedChronology } from "../../redux/slices/selectedChronologySlice";

// API imports
import {
  Chronology,
  useListCaseChronologiesCasesCaseIdChronologyGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Autocomplete, Box, Skeleton, TextField } from "@mui/material";

function ChronologySelector() {
  const dispatch = useDispatch();
  // Selector to get the selected chronology from the Redux store
  const selectedChronology = useSelector(
    (state: RootState) => state.chronologySelection.selectedItem
  );
  // Selector to get the updated events from the Redux store
  const updatedEvents = useSelector((state: RootState) => state.updatedEvents);

  //Get the selected case from the Redux store
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  // Get the selected case from the URL
  const selectedCaseParams = useParams<{ caseId: string }>().caseId;
  // if case id is not in the URL, use the selected case from Redux
  const selectedCase = selectedCaseParams || selectedCaseRedux;

  const [chronologyOption, setChronologyOption] = useState<Chronology | null>(
    null
  );

  const handleSelection = (event: any, newValue: Chronology | null) => {
    setChronologyOption(newValue);
    if (newValue) {
      if (newValue.id) {
        dispatch(setSelectedChronology(newValue.id));
      }
    }
  };

  // Fetch chronologies
  let content = null;
  const {
    data: listChronologies,
    error: listChronologiesError,
    isLoading: isListChronologiesLoading,
    isFetching: isListChronologiesFetching,
    refetch: refetchListChronologies,
  } = useListCaseChronologiesCasesCaseIdChronologyGetQuery({
    caseId: selectedCase,
  });

  useEffect(() => {
    refetchListChronologies();
  }, [updatedEvents, refetchListChronologies]);

  useEffect(() => {
    if (listChronologies) {
      const selected = listChronologies.data?.find(
        (c) => c.id === selectedChronology
      );
      setChronologyOption(selected || listChronologies.data?.[0] || null);
      if (!selected && listChronologies.data?.[0]) {
        if (listChronologies.data[0].id) {
          dispatch(setSelectedChronology(listChronologies.data[0].id));
        }
      }
    }
  }, [listChronologies, selectedChronology, dispatch]);

  if (isListChronologiesLoading || isListChronologiesFetching) {
    content = <Skeleton variant="rectangular" width={300} height={40} />;
  } else if (listChronologiesError) {
    content = <div>{JSON.stringify(listChronologiesError)}</div>;
  } else {
    if (!listChronologies) {
      content = <div>Select a case to view chronologies</div>;
    } else {
      content = (
        <Box minWidth={300}>
          <Autocomplete
            id="chronology-selector"
            value={chronologyOption}
            size="small"
            options={listChronologies.data || []}
            onChange={handleSelection}
            getOptionLabel={(option) => option.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Select Chronology"
              />
            )}
          />
        </Box>
      );
    }
  }
  return content;
}

export default ChronologySelector;
