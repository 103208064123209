// React imports
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";

// redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";
import { setSelectedPatient } from "../../redux/slices/selectedPatientSlice";

// Material-UI imports
import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import CaseSelector from "../../components/caseList/CaseSelector";
import SelectedPatientCharactersPanel from "./panels/SelectedPatientCharactersPanel";
import SelectedPatientChronologyPanel from "./panels/SelectedPatientChronologyPanel";
// import SelectedPatientDetailsPanel from "./panels/SelectedPatientDetailsPanel";
import SelectedPatientFilesPanel from "./panels/SelectedPatientFilesPanel";
import SelectedPatientTimelinePanel from "./panels/SelectedPatientTimelinePanel";
import SidelightPageBar from "../../components/navbar/appBar/SidelightPageBar";

//data imports
import {
  useGetCharacterCharactersCharacterIdGetQuery,
  useGetCaseCasesCaseIdGetQuery,
} from "../../api/jabsCentralApiPrivate";

const PageContentContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 5px 25px 25px 25px;
`;

// Tab panel interface
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
// Tab panel component
const TabPanel: React.FC<TabPanelProps> = ({
  children,
  index,
  value,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{}}>{children}</Box>}
    </div>
  );
};
// function to handle tab change with meaningful name in a11y
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SelectedPatientPage: React.FC = () => {
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = React.useState(0);

  const [pageTitle, setPageTitle] = useState("");
  const [caseTitle, setCaseTitle] = useState("");

  // set the tab value
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // Get the selected case from the Redux store
  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  // Get the selected case from the URL
  const selectedCase = useParams<{ caseId: string }>().caseId;
  // If the URL has a value, update the Redux store to match the URL value
  if (selectedCase !== selectedCaseRedux && selectedCase) {
    dispatch(setSelectedCase(selectedCase));
  }
  // Get the selected patient from the Redux store
  const selectedPatientRedux = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  // Get the selected patient from the URL
  const selectedPatient = useParams<{ patientId: string }>().patientId;
  // If the URL has a value, update the Redux store to match the URL value
  if (selectedPatient !== selectedPatientRedux && selectedPatient) {
    dispatch(setSelectedPatient(selectedPatient));
  }

  // Fetch case data
  const { data: caseData } = useGetCaseCasesCaseIdGetQuery({
    caseId: selectedCase || "",
  });

  useEffect(() => {
    if (caseData) {
      setCaseTitle(caseData.title ?? "");
    }
  }, [caseData]);

  // Fetch patient data
  const { data: patientData } = useGetCharacterCharactersCharacterIdGetQuery({
    characterId: selectedPatient || "",
  });

  // Set the patient title from the fetched data
  useEffect(() => {
    if (patientData) {
      setPageTitle(`${patientData.first_name} ${patientData.last_name}`);
    }
  }, [patientData]);

  return (
    <React.Fragment>
      <Helmet title={pageTitle} />
      <SidelightPageBar
        title={pageTitle}
        breakpointChildren="sm"
        breakpointRight="md"
        mobileHideRight={true}
        rightTools={<CaseSelector />}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab label="Chronology" {...a11yProps(0)} />
          <Tab label="Documents" {...a11yProps(1)} />
          <Tab label="Characters" {...a11yProps(2)} />
          <Tab label="Timeline" {...a11yProps(3)} />
        </Tabs>
      </SidelightPageBar>

      <PageContentContainer>
        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 3 }}>
          <Link color="inherit" href="/cases">
            Cases
          </Link>
          <Link color="inherit" href={`/case/${selectedCase}`}>
            {caseTitle}
          </Link>
          <Typography color="primary">{pageTitle}</Typography>
        </Breadcrumbs>
        {/* <TabPanel value={tabValue} index={0}>
          <SelectedPatientDetailsPanel />
        </TabPanel> */}
        <TabPanel value={tabValue} index={0}>
          <SelectedPatientChronologyPanel />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SelectedPatientFilesPanel />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <SelectedPatientCharactersPanel />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SelectedPatientTimelinePanel />
        </TabPanel>
      </PageContentContainer>
    </React.Fragment>
  );
};

export default SelectedPatientPage;
