import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface updatedCharactersTime {
  selectedItem: string;
}

const initialState: updatedCharactersTime = {
  selectedItem: "",
};

const updatedCharactersSlice = createSlice({
  name: "updatedCharacters",
  initialState,
  reducers: {
    setUpdatedCharacters(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearUpdatedCharacters(state) {
      state.selectedItem = "";
    },
  },
});

export const { setUpdatedCharacters, clearUpdatedCharacters } =
  updatedCharactersSlice.actions;
export default updatedCharactersSlice.reducer;
