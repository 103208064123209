// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

// Material-UI imports
import { Box, Stack } from "@mui/material";
// import Grid2 from "@mui/material/Unstable_Grid2";

// Local imports

// import GetCaseFiles from "../../../components/apiTesting/Files/GetCaseFiles";
// import FileTable from "../../../components/files/FileTable";
import FileTableCard from "../../../components/files/FileTableCard";
import GetFile from "../../../components/apiTesting/Files/GetFile";

const SelectedPatientFilesPanel: React.FC = () => {
  const newFileSelected = useSelector(
    (state: RootState) => state.fileSelection.selectedItem
  );

  const selectedCase = useParams<{ caseId: string }>().caseId;
  const selectedPatient = useParams<{ patientId: string }>().patientId;

  return (
    <Stack spacing={4} direction="row" sx={{ width: "100%" }}>
      <Box sx={{ width: "calc(100% - 450px)" }}>
        <FileTableCard
          characterId={selectedPatient || ""}
          caseId={selectedCase || ""}
        />
      </Box>
      <Box sx={{ width: "450px" }}>
        <GetFile fileId={newFileSelected || "0"} />
      </Box>
    </Stack>
  );
};

export default SelectedPatientFilesPanel;
