// Import necessary functions and types from Redux Toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for the state
interface SelectionCaseId {
  selectedItem: string | null; // The ID of the selected case, or null if no case is selected
}

// Define the initial state using the interface
const initialState: SelectionCaseId = {
  selectedItem: null, // Initially, no case is selected
};

// Create a slice for case selection
const selectedCaseSlice = createSlice({
  name: "caseSelection", // Name of the slice
  initialState, // Initial state of the slice
  reducers: {
    // Reducer to set the selected case
    setSelectedCase(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload; // Set the selected case ID to the payload
    },
    // Reducer to clear the selected case
    clearSelectedCase(state) {
      state.selectedItem = null; // Clear the selected case ID
    },
  },
});

// Export the actions generated by createSlice
export const { setSelectedCase, clearSelectedCase } = selectedCaseSlice.actions;

// Export the reducer to be used in the store
export default selectedCaseSlice.reducer;
