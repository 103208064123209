import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface updatedEventTime {
  selectedItem: string;
}

const initialState: updatedEventTime = {
  selectedItem: "",
};

const updatedEventSlice = createSlice({
  name: "updatedEvent",
  initialState,
  reducers: {
    setUpdatedEvent(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearUpdatedEvent(state) {
      state.selectedItem = "";
    },
  },
});

export const { setUpdatedEvent, clearUpdatedEvent } = updatedEventSlice.actions;
export default updatedEventSlice.reducer;
