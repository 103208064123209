import { useCallback } from "react";
import {
  getTokenPermissions,
  hasPermission,
  hasAnyPermission,
  hasAllPermissions,
} from "../utils/jwtPermissions";

export const usePermissions = () => {
  const getPermissions = useCallback(() => {
    return getTokenPermissions();
  }, []);

  const checkPermission = useCallback((permission: string) => {
    return hasPermission(permission);
  }, []);

  const checkAnyPermission = useCallback((permissions: string[]) => {
    return hasAnyPermission(permissions);
  }, []);

  const checkAllPermissions = useCallback((permissions: string[]) => {
    return hasAllPermissions(permissions);
  }, []);

  return {
    getPermissions,
    checkPermission,
    checkAnyPermission,
    checkAllPermissions,
  };
};
