// React imports
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import { setNewFileUrl } from "../../redux/slices/newFileUrlSlice";

// Axios import
// import axios from "axios";

// Material UI imports
import {
  Button,
  Stack,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { AttachFile, CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/system";

//API imports
import {
  GetPresignedUrlRequest,
  useGetPresignedUrlFilesPresignedUrlPostMutation,
} from "../../api/jabsCentralApiPrivate";

// Styled component for visually hidden input
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SingleFileUploader = () => {
  const dispatch = useDispatch();

  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const selectedPatientRedux = String(
    useSelector((state: RootState) => state.patientSelection.selectedItem)
  );
  const selectedPatientParams = useParams<{ patientId: string }>().patientId;
  const selectedPatient = selectedPatientParams || selectedPatientRedux;

  const selectedCaseRedux = String(
    useSelector((state: RootState) => state.caseSelection.selectedItem)
  );
  const selectedCaseParams = useParams<{ caseId: string }>().caseId;
  const selectedCase = selectedCaseParams || selectedCaseRedux;

  const [getPresignedUrl] = useGetPresignedUrlFilesPresignedUrlPostMutation();

  // Handler for file input change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]); // Set the selected file
      setError(null); // Clear any previous errors
    }
  };

  /**
   * Uploads a file to S3 using a presigned URL.
   * @param presignedUrl - The presigned S3 URL.
   */
  async function uploadFileToS3(presignedUrl: string): Promise<void> {
    if (!file) {
      console.error("No file to upload");
      return;
    }
    try {
      const response = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }
      setFile(null); // Reset the file input
      dispatch(setNewFileUrl(presignedUrl)); // track when new file is uploaded
      console.log("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError(
        error instanceof Error ? error.message : "Failed to upload file"
      );
    } finally {
      setIsUploading(false);
    }
  }

  // Function to upload the file using the presigned URL
  const doUpload = async (fileObject: any) => {
    if (fileObject.data) {
      const url = fileObject.data.url; // Get the presigned URL
      console.log("Presigned URL: ", url);
      console.log("File to upload: ", file);
      uploadFileToS3(url);
    }
  };

  // Handler for the upload button click event
  const handleUpload = async () => {
    if (file) {
      setIsUploading(true);
      setError(null); // Clear any previous errors
      const fileInfo: GetPresignedUrlRequest = {
        filename: file.name,
        type: file.type,
        case_id: selectedCase,
        character_id: selectedPatient,
        description: "document",
      };
      console.log("fileInfo: ", fileInfo);
      try {
        const response = await getPresignedUrl({
          getPresignedUrlRequest: fileInfo,
        });
        if ("error" in response) {
          const error = response.error;
          if ("status" in error) {
            setError(
              `Failed to get upload URL: ${
                error.data
                  ? (error.data as { detail: { message: string } }).detail
                      .message
                  : ""
              }`
            );
          } else {
            setError("Failed to get upload URL");
          }
          setIsUploading(false);
          return;
        }
        if (response) {
          console.log("response: ", response);
          doUpload(response);
        }
      } catch (error: unknown) {
        console.error("Error getting presigned URL:", error);
        // Handle different types of errors
        setError(
          error instanceof Error ? error.message : "Failed to get upload URL"
        );
        setIsUploading(false);
      }
    }
  };

  // Handler for closing the error snackbar
  const handleCloseError = () => {
    setError(null);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {/* Title */}
        {/* <Typography variant="h4" sx={{ mb: 2 }}>
          Documents
        </Typography> */}
        {/* Stack container for the buttons */}
        <Stack direction="row" spacing={2} alignItems="center">
          {/* Button to attach a document */}
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            size="small"
            tabIndex={-1}
            startIcon={<AttachFile />}
            sx={{ mr: 2 }}
            disabled={isUploading}
          >
            {file && file.name}
            {!file && "Attach document"}
            <VisuallyHiddenInput
              onChange={handleFileChange}
              type="file"
              disabled={isUploading}
            />
          </Button>
          {/* Button to upload the selected file */}
          {file && (
            <Button
              variant="contained"
              size="small"
              startIcon={
                isUploading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CloudUpload />
                )
              }
              onClick={handleUpload}
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Upload file"}
            </Button>
          )}
        </Stack>
      </Stack>

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SingleFileUploader;
