// React imports
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

// MUI imports
import { IconButton, Button, Tooltip } from "@mui/material";
import { Event } from "@mui/icons-material";

// Component imports
import AddEventForm from "./AddEventForm";

// Interface for component props
interface AddEventButtonOnToolbarProps {
  id: string; // The ID of the file
  src: string | undefined; // The source URL of the file
  name?: string; // The name of the file
  pageNumber: number; // The page number of the file
  fullButton?: boolean; // Optional prop to render a full button
}

function AddEventButtonOnToolbar({
  id,
  src,
  name,
  pageNumber,
  fullButton,
}: AddEventButtonOnToolbarProps) {
  const [open, setOpen] = useState(false); // State to control the dialog open/close
  const caseId = useParams<{ caseId: string }>().caseId || ""; // Get the case ID from the URL parameters

  return (
    <React.Fragment>
      {fullButton ? (
        <Tooltip title="Add Event to Chronology">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Event />}
            onClick={() => setOpen(true)} // Open the dialog when the button is clicked
          >
            Add Event
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title="Add Event to Chronology">
          <IconButton
            size="large"
            color="inherit"
            aria-label="Add to chronology"
            onClick={() => setOpen(true)} // Open the dialog when the button is clicked
          >
            <Event />
          </IconButton>
        </Tooltip>
      )}

      <AddEventForm
        open={open}
        onClose={() => setOpen(false)} // Close the dialog when the form is closed
        caseId={caseId}
        fileId={id}
        src={src || ""}
        fileName={name || ""}
        pageNumber={pageNumber}
      />
    </React.Fragment>
  );
}

export default AddEventButtonOnToolbar;
