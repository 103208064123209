// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import { Button } from "@mui/material";

//Component imports
import DeleteCaseModal from "./DeleteCaseModal";

interface DeleteCaseButtonProps {
  caseId: string;
  title: string;
}

function DeleteCaseButton({ caseId, title }: DeleteCaseButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button variant="contained" color="error" onClick={() => setOpen(true)}>
        Remove "{title}" from your case list
      </Button>
      <DeleteCaseModal
        caseId={caseId}
        title={title}
        open={open}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
}

export default DeleteCaseButton;
