import { jabsCentralApiPrivateBase as api } from "./jabsCentralApiPrivateBase";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    statusStatusGet: build.query<
      StatusStatusGetApiResponse,
      StatusStatusGetApiArg
    >({
      query: () => ({ url: `/status` }),
    }),
    createOrganizationUserOrganizationsOrgIdUsersPost: build.mutation<
      CreateOrganizationUserOrganizationsOrgIdUsersPostApiResponse,
      CreateOrganizationUserOrganizationsOrgIdUsersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.orgId}/users`,
        method: "POST",
        body: queryArg.systemUserInput,
      }),
    }),
    listOrganizationUsersOrganizationsOrgIdUsersGet: build.query<
      ListOrganizationUsersOrganizationsOrgIdUsersGetApiResponse,
      ListOrganizationUsersOrganizationsOrgIdUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.orgId}/users`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          status: queryArg.status,
          search: queryArg.search,
          role_id: queryArg.roleId,
        },
      }),
    }),
    createConditionEventCharactersCharacterIdTimelineConditionsPost:
      build.mutation<
        CreateConditionEventCharactersCharacterIdTimelineConditionsPostApiResponse,
        CreateConditionEventCharactersCharacterIdTimelineConditionsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/conditions`,
          method: "POST",
          body: queryArg.conditionEvent,
        }),
      }),
    createEncounterEventCharactersCharacterIdTimelineEncountersPost:
      build.mutation<
        CreateEncounterEventCharactersCharacterIdTimelineEncountersPostApiResponse,
        CreateEncounterEventCharactersCharacterIdTimelineEncountersPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/encounters`,
          method: "POST",
          body: queryArg.encounterEvent,
        }),
      }),
    createMeasurementEventCharactersCharacterIdTimelineMeasurementsPost:
      build.mutation<
        CreateMeasurementEventCharactersCharacterIdTimelineMeasurementsPostApiResponse,
        CreateMeasurementEventCharactersCharacterIdTimelineMeasurementsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/measurements`,
          method: "POST",
          body: queryArg.measurementEvent,
        }),
      }),
    createMedicationEventCharactersCharacterIdTimelineMedicationsPost:
      build.mutation<
        CreateMedicationEventCharactersCharacterIdTimelineMedicationsPostApiResponse,
        CreateMedicationEventCharactersCharacterIdTimelineMedicationsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/medications`,
          method: "POST",
          body: queryArg.medicationEvent,
        }),
      }),
    createObservationEventCharactersCharacterIdTimelineObservationsPost:
      build.mutation<
        CreateObservationEventCharactersCharacterIdTimelineObservationsPostApiResponse,
        CreateObservationEventCharactersCharacterIdTimelineObservationsPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/observations`,
          method: "POST",
          body: queryArg.observationEvent,
        }),
      }),
    createProcedureEventCharactersCharacterIdTimelineProceduresPost:
      build.mutation<
        CreateProcedureEventCharactersCharacterIdTimelineProceduresPostApiResponse,
        CreateProcedureEventCharactersCharacterIdTimelineProceduresPostApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/procedures`,
          method: "POST",
          body: queryArg.procedureEvent,
        }),
      }),
    deleteTimelineEventCharactersCharacterIdTimelineEventIdDelete:
      build.mutation<
        DeleteTimelineEventCharactersCharacterIdTimelineEventIdDeleteApiResponse,
        DeleteTimelineEventCharactersCharacterIdTimelineEventIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/characters/${queryArg.characterId}/timeline/${queryArg.eventId}`,
          method: "DELETE",
        }),
      }),
    updateTimelineEventCharactersCharacterIdTimelineEventIdPut: build.mutation<
      UpdateTimelineEventCharactersCharacterIdTimelineEventIdPutApiResponse,
      UpdateTimelineEventCharactersCharacterIdTimelineEventIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/timeline/${queryArg.eventId}`,
        method: "PUT",
        body: queryArg.updateTimelineEventRequest,
      }),
    }),
    getCharacterTimelineCharactersCharacterIdTimelineGet: build.query<
      GetCharacterTimelineCharactersCharacterIdTimelineGetApiResponse,
      GetCharacterTimelineCharactersCharacterIdTimelineGetApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/timeline`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          event_type: queryArg.eventType,
          provider_id: queryArg.providerId,
          status: queryArg.status,
          sort_by: queryArg.sortBy,
          sort_direction: queryArg.sortDirection,
        },
      }),
    }),
    addRolePermissionsRolesRoleIdPermissionsPost: build.mutation<
      AddRolePermissionsRolesRoleIdPermissionsPostApiResponse,
      AddRolePermissionsRolesRoleIdPermissionsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/roles/${queryArg.roleId}/permissions`,
        method: "POST",
        body: queryArg.requestBody,
      }),
    }),
    removeRolePermissionsRolesRoleIdPermissionsDelete: build.mutation<
      RemoveRolePermissionsRolesRoleIdPermissionsDeleteApiResponse,
      RemoveRolePermissionsRolesRoleIdPermissionsDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/roles/${queryArg.roleId}/permissions`,
        method: "DELETE",
        params: { permission_ids: queryArg.permissionIds },
      }),
    }),
    createRoleRolesPost: build.mutation<
      CreateRoleRolesPostApiResponse,
      CreateRoleRolesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/roles`,
        method: "POST",
        body: queryArg.systemRole,
      }),
    }),
    listRolesRolesGet: build.query<
      ListRolesRolesGetApiResponse,
      ListRolesRolesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/roles`,
        params: { status: queryArg.status, search: queryArg.search },
      }),
    }),
    getRoleRolesRoleIdGet: build.query<
      GetRoleRolesRoleIdGetApiResponse,
      GetRoleRolesRoleIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/roles/${queryArg.roleId}` }),
    }),
    updateRoleRolesRoleIdPut: build.mutation<
      UpdateRoleRolesRoleIdPutApiResponse,
      UpdateRoleRolesRoleIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/roles/${queryArg.roleId}`,
        method: "PUT",
        body: queryArg.systemRole,
      }),
    }),
    listPermissionsPermissionsGet: build.query<
      ListPermissionsPermissionsGetApiResponse,
      ListPermissionsPermissionsGetApiArg
    >({
      query: () => ({ url: `/permissions` }),
    }),
    createPermissionPermissionsPost: build.mutation<
      CreatePermissionPermissionsPostApiResponse,
      CreatePermissionPermissionsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/permissions`,
        method: "POST",
        body: queryArg.systemPermission,
      }),
    }),
    addCharacterAddressCharactersCharacterIdAddressesPost: build.mutation<
      AddCharacterAddressCharactersCharacterIdAddressesPostApiResponse,
      AddCharacterAddressCharactersCharacterIdAddressesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/addresses`,
        method: "POST",
        body: queryArg.address,
      }),
    }),
    getCharacterAddressesCharactersCharacterIdAddressesGet: build.query<
      GetCharacterAddressesCharactersCharacterIdAddressesGetApiResponse,
      GetCharacterAddressesCharactersCharacterIdAddressesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/addresses`,
      }),
    }),
    addCharacterContactCharactersCharacterIdContactPost: build.mutation<
      AddCharacterContactCharactersCharacterIdContactPostApiResponse,
      AddCharacterContactCharactersCharacterIdContactPostApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/contact`,
        method: "POST",
        body: queryArg.contactInfo,
      }),
    }),
    getCharacterContactsCharactersCharacterIdContactGet: build.query<
      GetCharacterContactsCharactersCharacterIdContactGetApiResponse,
      GetCharacterContactsCharactersCharacterIdContactGetApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/contact`,
      }),
    }),
    addCharacterIdentifierCharactersCharacterIdIdentifiersPost: build.mutation<
      AddCharacterIdentifierCharactersCharacterIdIdentifiersPostApiResponse,
      AddCharacterIdentifierCharactersCharacterIdIdentifiersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/identifiers`,
        method: "POST",
        body: queryArg.identifier,
      }),
    }),
    getCharacterIdentifiersCharactersCharacterIdIdentifiersGet: build.query<
      GetCharacterIdentifiersCharactersCharacterIdIdentifiersGetApiResponse,
      GetCharacterIdentifiersCharactersCharacterIdIdentifiersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}/identifiers`,
      }),
    }),
    archiveCharacterCharactersCharacterIdDelete: build.mutation<
      ArchiveCharacterCharactersCharacterIdDeleteApiResponse,
      ArchiveCharacterCharactersCharacterIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}`,
        method: "DELETE",
      }),
    }),
    getCharacterCharactersCharacterIdGet: build.query<
      GetCharacterCharactersCharacterIdGetApiResponse,
      GetCharacterCharactersCharacterIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/characters/${queryArg.characterId}` }),
    }),
    updateCharacterCharactersCharacterIdPut: build.mutation<
      UpdateCharacterCharactersCharacterIdPutApiResponse,
      UpdateCharacterCharactersCharacterIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/characters/${queryArg.characterId}`,
        method: "PUT",
        body: queryArg.character,
      }),
    }),
    createCharacterCasesCaseIdCharactersPost: build.mutation<
      CreateCharacterCasesCaseIdCharactersPostApiResponse,
      CreateCharacterCasesCaseIdCharactersPostApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/characters`,
        method: "POST",
        body: queryArg.character,
      }),
    }),
    listCaseCharactersCasesCaseIdCharactersGet: build.query<
      ListCaseCharactersCasesCaseIdCharactersGetApiResponse,
      ListCaseCharactersCasesCaseIdCharactersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/characters`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          character_type: queryArg.characterType,
          status: queryArg.status,
          search: queryArg.search,
          sort_by: queryArg.sortBy,
          sort_direction: queryArg.sortDirection,
        },
      }),
    }),
    archiveOrganizationOrganizationsOrgIdDelete: build.mutation<
      ArchiveOrganizationOrganizationsOrgIdDeleteApiResponse,
      ArchiveOrganizationOrganizationsOrgIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.orgId}`,
        method: "DELETE",
      }),
    }),
    getOrganizationOrganizationsOrgIdGet: build.query<
      GetOrganizationOrganizationsOrgIdGetApiResponse,
      GetOrganizationOrganizationsOrgIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/organizations/${queryArg.orgId}` }),
    }),
    updateOrganizationOrganizationsOrgIdPut: build.mutation<
      UpdateOrganizationOrganizationsOrgIdPutApiResponse,
      UpdateOrganizationOrganizationsOrgIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations/${queryArg.orgId}`,
        method: "PUT",
        body: queryArg.organization,
      }),
    }),
    createOrganizationOrganizationsPost: build.mutation<
      CreateOrganizationOrganizationsPostApiResponse,
      CreateOrganizationOrganizationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations`,
        method: "POST",
        body: queryArg.organization,
      }),
    }),
    listOrganizationsOrganizationsGet: build.query<
      ListOrganizationsOrganizationsGetApiResponse,
      ListOrganizationsOrganizationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/organizations`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          status: queryArg.status,
          search: queryArg.search,
          sort_by: queryArg.sortBy,
          sort_direction: queryArg.sortDirection,
        },
      }),
    }),
    archiveDocumentFilesFileIdDelete: build.mutation<
      ArchiveDocumentFilesFileIdDeleteApiResponse,
      ArchiveDocumentFilesFileIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}`,
        method: "DELETE",
      }),
    }),
    getDocumentFilesFileIdGet: build.query<
      GetDocumentFilesFileIdGetApiResponse,
      GetDocumentFilesFileIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}`,
        params: { include_text: queryArg.includeText },
      }),
    }),
    updateDocumentFilesFileIdPut: build.mutation<
      UpdateDocumentFilesFileIdPutApiResponse,
      UpdateDocumentFilesFileIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}`,
        method: "PUT",
        body: queryArg.updateDocumentRequest,
      }),
    }),
    createDocumentAnnotationFilesFileIdAnnotationsPost: build.mutation<
      CreateDocumentAnnotationFilesFileIdAnnotationsPostApiResponse,
      CreateDocumentAnnotationFilesFileIdAnnotationsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}/annotations`,
        method: "POST",
        body: queryArg.createDocumentAnnotationRequest,
      }),
    }),
    getDocumentAnnotationsFilesFileIdAnnotationsGet: build.query<
      GetDocumentAnnotationsFilesFileIdAnnotationsGetApiResponse,
      GetDocumentAnnotationsFilesFileIdAnnotationsGetApiArg
    >({
      query: (queryArg) => ({ url: `/files/${queryArg.fileId}/annotations` }),
    }),
    createDocumentVersionFilesFileIdVersionsPost: build.mutation<
      CreateDocumentVersionFilesFileIdVersionsPostApiResponse,
      CreateDocumentVersionFilesFileIdVersionsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}/versions`,
        method: "POST",
      }),
    }),
    getDocumentVersionsFilesFileIdVersionsGet: build.query<
      GetDocumentVersionsFilesFileIdVersionsGetApiResponse,
      GetDocumentVersionsFilesFileIdVersionsGetApiArg
    >({
      query: (queryArg) => ({ url: `/files/${queryArg.fileId}/versions` }),
    }),
    deleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDelete:
      build.mutation<
        DeleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDeleteApiResponse,
        DeleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/files/${queryArg.fileId}/annotations/${queryArg.annotationId}`,
          method: "DELETE",
        }),
      }),
    getDocumentExtractedTextFilesFileIdExtractedTextGet: build.query<
      GetDocumentExtractedTextFilesFileIdExtractedTextGetApiResponse,
      GetDocumentExtractedTextFilesFileIdExtractedTextGetApiArg
    >({
      query: (queryArg) => ({
        url: `/files/${queryArg.fileId}/extracted-text`,
      }),
    }),
    getPresignedUrlFilesPresignedUrlPost: build.mutation<
      GetPresignedUrlFilesPresignedUrlPostApiResponse,
      GetPresignedUrlFilesPresignedUrlPostApiArg
    >({
      query: (queryArg) => ({
        url: `/files/presigned-url`,
        method: "POST",
        body: queryArg.getPresignedUrlRequest,
      }),
    }),
    listCaseDocumentsCasesCaseIdFilesGet: build.query<
      ListCaseDocumentsCasesCaseIdFilesGetApiResponse,
      ListCaseDocumentsCasesCaseIdFilesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/files`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          filename: queryArg.filename,
          type: queryArg["type"],
          processing_status: queryArg.processingStatus,
          sort_by: queryArg.sortBy,
          sort_direction: queryArg.sortDirection,
        },
      }),
    }),
    archiveChronologyCasesCaseIdChronologyChronologyIdDelete: build.mutation<
      ArchiveChronologyCasesCaseIdChronologyChronologyIdDeleteApiResponse,
      ArchiveChronologyCasesCaseIdChronologyChronologyIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}`,
        method: "DELETE",
      }),
    }),
    getChronologyCasesCaseIdChronologyChronologyIdGet: build.query<
      GetChronologyCasesCaseIdChronologyChronologyIdGetApiResponse,
      GetChronologyCasesCaseIdChronologyChronologyIdGetApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}`,
        params: {
          event_type: queryArg.eventType,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
        },
      }),
    }),
    updateChronologyCasesCaseIdChronologyChronologyIdPut: build.mutation<
      UpdateChronologyCasesCaseIdChronologyChronologyIdPutApiResponse,
      UpdateChronologyCasesCaseIdChronologyChronologyIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}`,
        method: "PUT",
        body: queryArg.createChronologyRequest,
      }),
    }),
    createChronologyCasesCaseIdChronologyPost: build.mutation<
      CreateChronologyCasesCaseIdChronologyPostApiResponse,
      CreateChronologyCasesCaseIdChronologyPostApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/chronology`,
        method: "POST",
        body: queryArg.createChronologyRequest,
      }),
    }),
    listCaseChronologiesCasesCaseIdChronologyGet: build.query<
      ListCaseChronologiesCasesCaseIdChronologyGetApiResponse,
      ListCaseChronologiesCasesCaseIdChronologyGetApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/chronology`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          event_type: queryArg.eventType,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
        },
      }),
    }),
    createChronologyEventCasesCaseIdChronologyChronologyIdEventPost:
      build.mutation<
        CreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostApiResponse,
        CreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostApiArg
      >({
        query: (queryArg) => ({
          url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}/event`,
          method: "POST",
          body: queryArg.chronologyEventInput,
        }),
      }),
    deleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDelete:
      build.mutation<
        DeleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDeleteApiResponse,
        DeleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}/event/${queryArg.eventId}`,
          method: "DELETE",
        }),
      }),
    updateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPut:
      build.mutation<
        UpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutApiResponse,
        UpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutApiArg
      >({
        query: (queryArg) => ({
          url: `/cases/${queryArg.caseId}/chronology/${queryArg.chronologyId}/event/${queryArg.eventId}`,
          method: "PUT",
          body: queryArg.chronologyEventInput,
        }),
      }),
    archiveCaseCasesCaseIdDelete: build.mutation<
      ArchiveCaseCasesCaseIdDeleteApiResponse,
      ArchiveCaseCasesCaseIdDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}`,
        method: "DELETE",
      }),
    }),
    getCaseCasesCaseIdGet: build.query<
      GetCaseCasesCaseIdGetApiResponse,
      GetCaseCasesCaseIdGetApiArg
    >({
      query: (queryArg) => ({ url: `/cases/${queryArg.caseId}` }),
    }),
    updateCaseCasesCaseIdPut: build.mutation<
      UpdateCaseCasesCaseIdPutApiResponse,
      UpdateCaseCasesCaseIdPutApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}`,
        method: "PUT",
        body: queryArg.case,
      }),
    }),
    createCaseCasesPost: build.mutation<
      CreateCaseCasesPostApiResponse,
      CreateCaseCasesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/cases`,
        method: "POST",
        body: queryArg.case,
      }),
    }),
    listCasesCasesGet: build.query<
      ListCasesCasesGetApiResponse,
      ListCasesCasesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/cases`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          case_status: queryArg.caseStatus,
          case_type: queryArg.caseType,
          status: queryArg.status,
          start_date_from: queryArg.startDateFrom,
          start_date_to: queryArg.startDateTo,
          search: queryArg.search,
          sort_by: queryArg.sortBy,
          sort_direction: queryArg.sortDirection,
        },
      }),
    }),
    getCaseAccessCasesCaseIdAccessGet: build.query<
      GetCaseAccessCasesCaseIdAccessGetApiResponse,
      GetCaseAccessCasesCaseIdAccessGetApiArg
    >({
      query: (queryArg) => ({ url: `/cases/${queryArg.caseId}/access` }),
    }),
    grantCaseAccessCasesCaseIdAccessPost: build.mutation<
      GrantCaseAccessCasesCaseIdAccessPostApiResponse,
      GrantCaseAccessCasesCaseIdAccessPostApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/access`,
        method: "POST",
        body: queryArg.grantCaseAccessRequest,
      }),
    }),
    revokeCaseAccessCasesCaseIdAccessDelete: build.mutation<
      RevokeCaseAccessCasesCaseIdAccessDeleteApiResponse,
      RevokeCaseAccessCasesCaseIdAccessDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/access`,
        method: "DELETE",
        params: { user_id: queryArg.userId },
      }),
    }),
    updateCaseStatusCasesCaseIdStatusPut: build.mutation<
      UpdateCaseStatusCasesCaseIdStatusPutApiResponse,
      UpdateCaseStatusCasesCaseIdStatusPutApiArg
    >({
      query: (queryArg) => ({
        url: `/cases/${queryArg.caseId}/status`,
        method: "PUT",
        body: queryArg.updateCaseStatusRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as jabsCentralApiPrivate };
export type StatusStatusGetApiResponse =
  /** status 200 Successful Response */ Status;
export type StatusStatusGetApiArg = void;
export type CreateOrganizationUserOrganizationsOrgIdUsersPostApiResponse =
  /** status 200 Successful Response */
  SystemUser | /** status 201 User created successfully */ SystemUser;
export type CreateOrganizationUserOrganizationsOrgIdUsersPostApiArg = {
  orgId: string;
  systemUserInput: SystemUser2;
};
export type ListOrganizationUsersOrganizationsOrgIdUsersGetApiResponse =
  /** status 200 List of users retrieved successfully */ PaginatedSystemUsers;
export type ListOrganizationUsersOrganizationsOrgIdUsersGetApiArg = {
  orgId: string;
  page?: number;
  limit?: number;
  status?: string;
  /** Search users by username or email */
  search?: string;
  /** Filter users by role */
  roleId?: string;
};
export type CreateConditionEventCharactersCharacterIdTimelineConditionsPostApiResponse =
  /** status 200 Successful Response */
  | ConditionEvent
  | /** status 201 Condition created successfully */ ConditionEvent;
export type CreateConditionEventCharactersCharacterIdTimelineConditionsPostApiArg =
  {
    characterId: string;
    conditionEvent: ConditionEvent;
  };
export type CreateEncounterEventCharactersCharacterIdTimelineEncountersPostApiResponse =
  /** status 200 Successful Response */
  | EncounterEvent
  | /** status 201 Encounter created successfully */ EncounterEvent;
export type CreateEncounterEventCharactersCharacterIdTimelineEncountersPostApiArg =
  {
    characterId: string;
    encounterEvent: EncounterEvent;
  };
export type CreateMeasurementEventCharactersCharacterIdTimelineMeasurementsPostApiResponse =
  /** status 200 Successful Response */
  | MeasurementEvent
  | /** status 201 Measurement created successfully */ MeasurementEvent;
export type CreateMeasurementEventCharactersCharacterIdTimelineMeasurementsPostApiArg =
  {
    characterId: string;
    measurementEvent: MeasurementEvent;
  };
export type CreateMedicationEventCharactersCharacterIdTimelineMedicationsPostApiResponse =
  /** status 200 Successful Response */
  | MedicationEvent
  | /** status 201 Medication created successfully */ MedicationEvent;
export type CreateMedicationEventCharactersCharacterIdTimelineMedicationsPostApiArg =
  {
    characterId: string;
    medicationEvent: MedicationEvent;
  };
export type CreateObservationEventCharactersCharacterIdTimelineObservationsPostApiResponse =
  /** status 200 Successful Response */
  | ObservationEvent
  | /** status 201 Observation created successfully */ ObservationEvent;
export type CreateObservationEventCharactersCharacterIdTimelineObservationsPostApiArg =
  {
    characterId: string;
    observationEvent: ObservationEvent;
  };
export type CreateProcedureEventCharactersCharacterIdTimelineProceduresPostApiResponse =
  /** status 200 Successful Response */
  | ProcedureEvent
  | /** status 201 Procedure created successfully */ ProcedureEvent;
export type CreateProcedureEventCharactersCharacterIdTimelineProceduresPostApiArg =
  {
    characterId: string;
    procedureEvent: ProcedureEvent;
  };
export type DeleteTimelineEventCharactersCharacterIdTimelineEventIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Event deleted successfully */ void;
export type DeleteTimelineEventCharactersCharacterIdTimelineEventIdDeleteApiArg =
  {
    characterId: string;
    eventId: string;
  };
export type UpdateTimelineEventCharactersCharacterIdTimelineEventIdPutApiResponse =
  /** status 200 Event updated successfully */ UpdateTimelineEventRequest;
export type UpdateTimelineEventCharactersCharacterIdTimelineEventIdPutApiArg = {
  characterId: string;
  eventId: string;
  updateTimelineEventRequest: UpdateTimelineEventRequest;
};
export type GetCharacterTimelineCharactersCharacterIdTimelineGetApiResponse =
  /** status 200 Timeline retrieved successfully */ PaginatedTimelineEvents;
export type GetCharacterTimelineCharactersCharacterIdTimelineGetApiArg = {
  characterId: string;
  page?: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  eventType?: string;
  providerId?: string;
  status?: string;
  sortBy?: string;
  sortDirection?: string;
};
export type AddRolePermissionsRolesRoleIdPermissionsPostApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Permissions added successfully */ void;
export type AddRolePermissionsRolesRoleIdPermissionsPostApiArg = {
  roleId: string;
  requestBody: string[];
};
export type RemoveRolePermissionsRolesRoleIdPermissionsDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Permissions removed successfully */ void;
export type RemoveRolePermissionsRolesRoleIdPermissionsDeleteApiArg = {
  roleId: string;
  /** Array of permission IDs to remove */
  permissionIds?: string[];
};
export type CreateRoleRolesPostApiResponse =
  /** status 200 Successful Response */
  SystemRole | /** status 201 Role created successfully */ SystemRole;
export type CreateRoleRolesPostApiArg = {
  systemRole: SystemRole;
};
export type ListRolesRolesGetApiResponse =
  /** status 200 List of roles retrieved successfully */ SystemRole[];
export type ListRolesRolesGetApiArg = {
  status?: string;
  /** Search roles by name */
  search?: string;
};
export type GetRoleRolesRoleIdGetApiResponse =
  /** status 200 Role details retrieved successfully */ SystemRole;
export type GetRoleRolesRoleIdGetApiArg = {
  roleId: string;
};
export type UpdateRoleRolesRoleIdPutApiResponse =
  /** status 200 Role updated successfully */ SystemRole;
export type UpdateRoleRolesRoleIdPutApiArg = {
  roleId: string;
  systemRole: SystemRole;
};
export type ListPermissionsPermissionsGetApiResponse =
  /** status 200 List of permissions retrieved successfully */ SystemPermission[];
export type ListPermissionsPermissionsGetApiArg = void;
export type CreatePermissionPermissionsPostApiResponse =
  /** status 200 Successful Response */
  | SystemPermission
  | /** status 201 Permission created successfully */ SystemPermission;
export type CreatePermissionPermissionsPostApiArg = {
  systemPermission: SystemPermission;
};
export type AddCharacterAddressCharactersCharacterIdAddressesPostApiResponse =
  /** status 200 Successful Response */
  Address | /** status 201 Address added successfully */ Address;
export type AddCharacterAddressCharactersCharacterIdAddressesPostApiArg = {
  characterId: string;
  address: Address;
};
export type GetCharacterAddressesCharactersCharacterIdAddressesGetApiResponse =
  /** status 200 Character addresses retrieved successfully */ Address[];
export type GetCharacterAddressesCharactersCharacterIdAddressesGetApiArg = {
  characterId: string;
};
export type AddCharacterContactCharactersCharacterIdContactPostApiResponse =
  /** status 200 Successful Response */
  | ContactInfo
  | /** status 201 Contact information added successfully */ ContactInfo;
export type AddCharacterContactCharactersCharacterIdContactPostApiArg = {
  characterId: string;
  contactInfo: ContactInfo;
};
export type GetCharacterContactsCharactersCharacterIdContactGetApiResponse =
  /** status 200 Character contact information retrieved successfully */ ContactInfo[];
export type GetCharacterContactsCharactersCharacterIdContactGetApiArg = {
  characterId: string;
};
export type AddCharacterIdentifierCharactersCharacterIdIdentifiersPostApiResponse =
  /** status 200 Successful Response */
  Identifier | /** status 201 Identifier added successfully */ Identifier;
export type AddCharacterIdentifierCharactersCharacterIdIdentifiersPostApiArg = {
  characterId: string;
  identifier: Identifier;
};
export type GetCharacterIdentifiersCharactersCharacterIdIdentifiersGetApiResponse =
  /** status 200 Character identifiers retrieved successfully */ Identifier[];
export type GetCharacterIdentifiersCharactersCharacterIdIdentifiersGetApiArg = {
  characterId: string;
};
export type ArchiveCharacterCharactersCharacterIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Character archived successfully */ void;
export type ArchiveCharacterCharactersCharacterIdDeleteApiArg = {
  characterId: string;
};
export type GetCharacterCharactersCharacterIdGetApiResponse =
  /** status 200 Character details retrieved successfully */ Character;
export type GetCharacterCharactersCharacterIdGetApiArg = {
  characterId: string;
};
export type UpdateCharacterCharactersCharacterIdPutApiResponse =
  /** status 200 Character updated successfully */ Character;
export type UpdateCharacterCharactersCharacterIdPutApiArg = {
  characterId: string;
  character: Character;
};
export type CreateCharacterCasesCaseIdCharactersPostApiResponse =
  /** status 200 Successful Response */
  Character | /** status 201 Character created successfully */ Character;
export type CreateCharacterCasesCaseIdCharactersPostApiArg = {
  caseId: string;
  character: Character;
};
export type ListCaseCharactersCasesCaseIdCharactersGetApiResponse =
  /** status 200 List of characters retrieved successfully */ PaginatedCharacters;
export type ListCaseCharactersCasesCaseIdCharactersGetApiArg = {
  caseId: string;
  page?: number;
  limit?: number;
  characterType?: string;
  status?: string;
  /** Search in first_name, last_name, or identifiers */
  search?: string;
  sortBy?: string;
  sortDirection?: string;
};
export type ArchiveOrganizationOrganizationsOrgIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Organization archived successfully */ void;
export type ArchiveOrganizationOrganizationsOrgIdDeleteApiArg = {
  orgId: string;
};
export type GetOrganizationOrganizationsOrgIdGetApiResponse =
  /** status 200 Organization details retrieved successfully */ Organization;
export type GetOrganizationOrganizationsOrgIdGetApiArg = {
  orgId: string;
};
export type UpdateOrganizationOrganizationsOrgIdPutApiResponse =
  /** status 200 Organization updated successfully */ Organization;
export type UpdateOrganizationOrganizationsOrgIdPutApiArg = {
  orgId: string;
  organization: Organization;
};
export type CreateOrganizationOrganizationsPostApiResponse =
  /** status 200 Successful Response */
  | Organization
  | /** status 201 Organization created successfully */ Organization;
export type CreateOrganizationOrganizationsPostApiArg = {
  organization: Organization;
};
export type ListOrganizationsOrganizationsGetApiResponse =
  /** status 200 List of organizations retrieved successfully */ PaginatedOrganizations;
export type ListOrganizationsOrganizationsGetApiArg = {
  page?: number;
  limit?: number;
  status?: string;
  /** Search organizations by name */
  search?: string;
  sortBy?: string;
  sortDirection?: string;
};
export type ArchiveDocumentFilesFileIdDeleteApiResponse =
  /** status 204 Document archived successfully */ void;
export type ArchiveDocumentFilesFileIdDeleteApiArg = {
  fileId: string;
};
export type GetDocumentFilesFileIdGetApiResponse =
  /** status 200 Document retrieved successfully */ Document;
export type GetDocumentFilesFileIdGetApiArg = {
  fileId: string;
  /** Include extracted text in response */
  includeText?: boolean;
};
export type UpdateDocumentFilesFileIdPutApiResponse =
  /** status 200 Document updated successfully */ Document;
export type UpdateDocumentFilesFileIdPutApiArg = {
  fileId: string;
  updateDocumentRequest: UpdateDocumentRequest;
};
export type CreateDocumentAnnotationFilesFileIdAnnotationsPostApiResponse =
  /** status 201 Annotation created successfully */ FileAnnotation;
export type CreateDocumentAnnotationFilesFileIdAnnotationsPostApiArg = {
  fileId: string;
  createDocumentAnnotationRequest: CreateDocumentAnnotationRequest;
};
export type GetDocumentAnnotationsFilesFileIdAnnotationsGetApiResponse =
  /** status 200 Document annotations retrieved successfully */ FileAnnotation[];
export type GetDocumentAnnotationsFilesFileIdAnnotationsGetApiArg = {
  fileId: string;
};
export type CreateDocumentVersionFilesFileIdVersionsPostApiResponse =
  /** status 200 Presigned URL for new version upload */ CreateDocumentVersion200Response;
export type CreateDocumentVersionFilesFileIdVersionsPostApiArg = {
  fileId: string;
};
export type GetDocumentVersionsFilesFileIdVersionsGetApiResponse =
  /** status 200 Document versions retrieved successfully */ Document[];
export type GetDocumentVersionsFilesFileIdVersionsGetApiArg = {
  fileId: string;
};
export type DeleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDeleteApiResponse =
  /** status 204 Annotation deleted successfully */ void;
export type DeleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDeleteApiArg =
  {
    fileId: string;
    annotationId: string;
  };
export type GetDocumentExtractedTextFilesFileIdExtractedTextGetApiResponse =
  /** status 200 Document&#39;s extracted text retrieved successfully */ ExtractedText;
export type GetDocumentExtractedTextFilesFileIdExtractedTextGetApiArg = {
  fileId: string;
};
export type GetPresignedUrlFilesPresignedUrlPostApiResponse =
  /** status 200 Presigned URL generated successfully */ GetPresignedUrl200Response;
export type GetPresignedUrlFilesPresignedUrlPostApiArg = {
  getPresignedUrlRequest: GetPresignedUrlRequest;
};
export type ListCaseDocumentsCasesCaseIdFilesGetApiResponse =
  /** status 200 List of documents retrieved successfully */ PaginatedDocuments;
export type ListCaseDocumentsCasesCaseIdFilesGetApiArg = {
  caseId: string;
  page?: number;
  limit?: number;
  filename?: string;
  type?: string;
  processingStatus?: string;
  sortBy?: string;
  sortDirection?: string;
};
export type ArchiveChronologyCasesCaseIdChronologyChronologyIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Chronology archived successfully */ void;
export type ArchiveChronologyCasesCaseIdChronologyChronologyIdDeleteApiArg = {
  caseId: string;
  chronologyId: string;
};
export type GetChronologyCasesCaseIdChronologyChronologyIdGetApiResponse =
  /** status 200 Chronology retrieved successfully */ Chronology;
export type GetChronologyCasesCaseIdChronologyChronologyIdGetApiArg = {
  caseId: string;
  chronologyId: string;
  eventType?: string;
  startDate?: string;
  endDate?: string;
};
export type UpdateChronologyCasesCaseIdChronologyChronologyIdPutApiResponse =
  /** status 200 Chronology updated successfully */ Chronology;
export type UpdateChronologyCasesCaseIdChronologyChronologyIdPutApiArg = {
  caseId: string;
  chronologyId: string;
  createChronologyRequest: CreateChronologyRequest;
};
export type CreateChronologyCasesCaseIdChronologyPostApiResponse =
  /** status 200 Successful Response */
  Chronology | /** status 201 Chronology created successfully */ Chronology;
export type CreateChronologyCasesCaseIdChronologyPostApiArg = {
  caseId: string;
  createChronologyRequest: CreateChronologyRequest;
};
export type ListCaseChronologiesCasesCaseIdChronologyGetApiResponse =
  /** status 200 List of chronologies retrieved successfully */ PaginatedChronologies;
export type ListCaseChronologiesCasesCaseIdChronologyGetApiArg = {
  caseId: string;
  page?: number;
  limit?: number;
  eventType?: string;
  startDate?: string;
  endDate?: string;
};
export type CreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostApiResponse =
  /** status 200 Successful Response */
  | ChronologyEvent
  | /** status 201 Event created successfully */ ChronologyEvent;
export type CreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostApiArg =
  {
    caseId: string;
    chronologyId: string;
    chronologyEventInput: ChronologyEvent2;
  };
export type DeleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Chronology event deleted successfully */ void;
export type DeleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDeleteApiArg =
  {
    caseId: string;
    chronologyId: string;
    eventId: string;
  };
export type UpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutApiResponse =
  /** status 200 Event updated successfully */ ChronologyEvent;
export type UpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutApiArg =
  {
    caseId: string;
    chronologyId: string;
    eventId: string;
    chronologyEventInput: ChronologyEvent2;
  };
export type ArchiveCaseCasesCaseIdDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Case archived successfully */ void;
export type ArchiveCaseCasesCaseIdDeleteApiArg = {
  /** Unique identifier of the case */
  caseId: string;
};
export type GetCaseCasesCaseIdGetApiResponse =
  /** status 200 Case details retrieved successfully */ Case;
export type GetCaseCasesCaseIdGetApiArg = {
  /** Unique identifier of the case */
  caseId: string;
};
export type UpdateCaseCasesCaseIdPutApiResponse =
  /** status 200 Case updated successfully */ Case;
export type UpdateCaseCasesCaseIdPutApiArg = {
  /** Unique identifier of the case */
  caseId: string;
  case: Case;
};
export type CreateCaseCasesPostApiResponse =
  /** status 200 Successful Response */
  Case | /** status 201 Case created successfully */ Case;
export type CreateCaseCasesPostApiArg = {
  case: Case;
};
export type ListCasesCasesGetApiResponse =
  /** status 200 List of cases retrieved successfully */ PaginatedCases;
export type ListCasesCasesGetApiArg = {
  page?: number;
  limit?: number;
  /** Filter cases by their current status */
  caseStatus?: string;
  /** Filter cases by type */
  caseType?: string;
  /** Filter cases by their system status */
  status?: string;
  /** Filter cases with start date after this date */
  startDateFrom?: string;
  /** Filter cases with start date before this date */
  startDateTo?: string;
  /** Search term to filter cases by title or description */
  search?: string;
  sortBy?: string;
  sortDirection?: string;
};
export type GetCaseAccessCasesCaseIdAccessGetApiResponse =
  /** status 200 List of users with access retrieved successfully */ SystemUser[];
export type GetCaseAccessCasesCaseIdAccessGetApiArg = {
  /** Unique identifier of the case */
  caseId: string;
};
export type GrantCaseAccessCasesCaseIdAccessPostApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Access granted successfully */ void;
export type GrantCaseAccessCasesCaseIdAccessPostApiArg = {
  /** Unique identifier of the case */
  caseId: string;
  grantCaseAccessRequest: GrantCaseAccessRequest;
};
export type RevokeCaseAccessCasesCaseIdAccessDeleteApiResponse =
  /** status 200 Successful Response */
  any | /** status 204 Access revoked successfully */ void;
export type RevokeCaseAccessCasesCaseIdAccessDeleteApiArg = {
  /** Unique identifier of the case */
  caseId: string;
  /** ID of the user to revoke access from */
  userId?: string;
};
export type UpdateCaseStatusCasesCaseIdStatusPutApiResponse =
  /** status 200 Case status updated successfully */ Case;
export type UpdateCaseStatusCasesCaseIdStatusPutApiArg = {
  /** Unique identifier of the case */
  caseId: string;
  updateCaseStatusRequest: UpdateCaseStatusRequest;
};
export type Status = {
  /** The status of the internet egress */
  internet_egress_status?: string | null;
  /** The status of the database */
  database_status?: string | null;
};
export type SystemPermission = {
  id?: string | null;
  name: string;
  description?: string | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type SystemRole = {
  id?: string | null;
  name: string;
  description?: string | null;
  permissions?: SystemPermission[] | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type SystemUser = {
  id?: string | null;
  username: string;
  email: string;
  roles?: SystemRole[] | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type SystemUser2 = {
  id?: string | null;
  username: string;
  email: string;
  roles?: SystemRole[] | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type PaginatedSystemUsers = {
  data?: SystemUser[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type ConditionEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  condition_name?: string | null;
  onset_date?: string | null;
  resolution_date?: string | null;
  notes?: string | null;
};
export type EncounterEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  location?: string | null;
  reason?: string | null;
};
export type MeasurementEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  measurement_type?: string | null;
  value?: number | null;
  unit?: string | null;
};
export type MedicationEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  name?: string | null;
  dosage?: string | null;
  frequency?: string | null;
  start_date?: string | null;
};
export type ObservationEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  observation_type?: string | null;
  value?: string | null;
};
export type ProcedureEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  procedure_name?: string | null;
  notes?: string | null;
};
export type UpdateTimelineEventRequest = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
  location?: string | null;
  reason?: string | null;
  procedure_name?: string | null;
  notes?: string | null;
  observation_type?: string | null;
  value?: number | null;
  measurement_type?: string | null;
  unit?: string | null;
  name?: string | null;
  dosage?: string | null;
  frequency?: string | null;
  start_date?: string | null;
  condition_name?: string | null;
  onset_date?: string | null;
  resolution_date?: string | null;
};
export type BaseTimelineEvent = {
  id?: string | null;
  character_id?: string | null;
  event_type?: string | null;
  description?: string | null;
  file_id?: string | null;
  page_reference?: string | null;
  begin_date?: string | null;
  end_date?: string | null;
  status?: string | null;
  metadata?: object | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type PaginatedTimelineEvents = {
  data?: BaseTimelineEvent[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type Address = {
  address_line_1: string;
  address_line_2?: string | null;
  address_line_3?: string | null;
  locality?: string | null;
  region?: string | null;
  postal_code: string;
  country: string;
  status?: string | null;
};
export type ContactInfo = {
  email: string;
  phone_number: string;
  status?: string | null;
};
export type Identifier = {
  id_type: string;
  id_value: string;
  status?: string | null;
};
export type Error = {
  code?: number | null;
  message?: string | null;
};
export type Tag = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  color?: string | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type Character = {
  id?: string | null;
  character_type?: string | null;
  character_type_description?: string | null;
  identifier?: Identifier[] | null;
  first_name?: string | null;
  last_name?: string | null;
  date_of_birth?: string | null;
  gender?: string | null;
  contact_information?: ContactInfo[] | null;
  address?: Address[] | null;
  case_id?: string | null;
  status?: string | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type PaginatedCharacters = {
  data?: Character[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type Organization = {
  id?: string | null;
  name: string;
  address?: Address | null;
  contact_information?: ContactInfo | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type PaginatedOrganizations = {
  data?: Organization[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type ExtractedText = {
  text?: string | null;
  extraction_method?: string | null;
  confidence_score?: number | null;
  extracted_at?: string | null;
  page_number?: number | null;
  language?: string | null;
  analysis_metadata?: object | null;
  details?: string | null;
  blocks?: any[] | null;
  forms?: any[] | null;
  tables?: any[] | null;
};
export type FileAnnotation = {
  id?: string | null;
  user_id?: string | null;
  annotation: string;
  created_at?: string | null;
};
export type Document = {
  id?: string | null;
  filename: string;
  description?: string | null;
  type: string;
  file_type?: string | null;
  case_id: string;
  lawyer_id?: string | null;
  character_id?: string | null;
  url?: string | null;
  location?: string | null;
  version?: number | null;
  processing_status?: string | null;
  extracted_text?: ExtractedText[] | null;
  annotations?: FileAnnotation[] | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type UpdateDocumentRequest = {
  filename?: string | null;
  description?: string | null;
  type?: string | null;
};
export type CreateDocumentAnnotationRequest = {
  annotation: string;
};
export type CreateDocumentVersion200Response = {
  url?: string | null;
  fields?: object | null;
  version?: number | null;
};
export type GetPresignedUrl200Response = {
  url?: string | null;
  fields?: object | null;
  document_id?: string | null;
};
export type GetPresignedUrlRequest = {
  filename: string;
  type: string;
  case_id: string;
  character_id?: string | null;
  description?: string | null;
};
export type PaginatedDocuments = {
  data?: Document[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type Comment = {
  id?: string | null;
  user: SystemUser;
  comment: string;
  created_at: string;
  updated_at?: string | null;
};
export type ChronologyEvent = {
  id?: string | null;
  location?: string | null;
  file_id?: string | null;
  characters?: Character[] | null;
  page_reference?: string | null;
  event_type?: string | null;
  event_description?: string | null;
  event_date?: string | null;
  event_date_end?: string | null;
  extract_summary?: string | null;
  reviewer_comments?: Comment[] | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type Chronology = {
  id?: string | null;
  characters?: Character[] | null;
  name?: string | null;
  description?: string | null;
  status?: string | null;
  events?: ChronologyEvent[] | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type CreateChronologyRequestTagsInner = {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  color?: string | null;
  status?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type CreateChronologyRequest = {
  id?: string | null;
  characters?: string[] | null;
  name?: string | null;
  description?: string | null;
  status?: string | null;
  tags?: CreateChronologyRequestTagsInner[] | null;
};
export type PaginatedChronologies = {
  data?: Chronology[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type Comment2 = {
  id?: string | null;
  user: SystemUser2;
  comment: string;
  created_at: string;
  updated_at?: string | null;
};
export type ChronologyEvent2 = {
  id?: string | null;
  location?: string | null;
  file_id?: string | null;
  characters?: Character[] | null;
  page_reference?: string | null;
  event_type?: string | null;
  event_description?: string | null;
  event_date?: string | null;
  event_date_end?: string | null;
  extract_summary?: string | null;
  reviewer_comments?: Comment2[] | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type Case = {
  id?: string | null;
  title?: string | null;
  case_type?: string | null;
  case_status?: string | null;
  start_date?: string | null;
  end_date?: string | null;
  description?: string | null;
  access?: string[] | null;
  status?: string | null;
  tags?: Tag[] | null;
  created_at?: string | null;
  updated_at?: string | null;
};
export type PaginatedCases = {
  data?: Case[] | null;
  total?: number | null;
  page?: number | null;
  limit?: number | null;
  total_pages?: number | null;
};
export type GrantCaseAccessRequest = {
  user_id: string;
};
export type UpdateCaseStatusRequest = {
  case_status: string;
  end_date?: string | null;
};
export const {
  useStatusStatusGetQuery,
  useCreateOrganizationUserOrganizationsOrgIdUsersPostMutation,
  useListOrganizationUsersOrganizationsOrgIdUsersGetQuery,
  useCreateConditionEventCharactersCharacterIdTimelineConditionsPostMutation,
  useCreateEncounterEventCharactersCharacterIdTimelineEncountersPostMutation,
  useCreateMeasurementEventCharactersCharacterIdTimelineMeasurementsPostMutation,
  useCreateMedicationEventCharactersCharacterIdTimelineMedicationsPostMutation,
  useCreateObservationEventCharactersCharacterIdTimelineObservationsPostMutation,
  useCreateProcedureEventCharactersCharacterIdTimelineProceduresPostMutation,
  useDeleteTimelineEventCharactersCharacterIdTimelineEventIdDeleteMutation,
  useUpdateTimelineEventCharactersCharacterIdTimelineEventIdPutMutation,
  useGetCharacterTimelineCharactersCharacterIdTimelineGetQuery,
  useAddRolePermissionsRolesRoleIdPermissionsPostMutation,
  useRemoveRolePermissionsRolesRoleIdPermissionsDeleteMutation,
  useCreateRoleRolesPostMutation,
  useListRolesRolesGetQuery,
  useGetRoleRolesRoleIdGetQuery,
  useUpdateRoleRolesRoleIdPutMutation,
  useListPermissionsPermissionsGetQuery,
  useCreatePermissionPermissionsPostMutation,
  useAddCharacterAddressCharactersCharacterIdAddressesPostMutation,
  useGetCharacterAddressesCharactersCharacterIdAddressesGetQuery,
  useAddCharacterContactCharactersCharacterIdContactPostMutation,
  useGetCharacterContactsCharactersCharacterIdContactGetQuery,
  useAddCharacterIdentifierCharactersCharacterIdIdentifiersPostMutation,
  useGetCharacterIdentifiersCharactersCharacterIdIdentifiersGetQuery,
  useArchiveCharacterCharactersCharacterIdDeleteMutation,
  useGetCharacterCharactersCharacterIdGetQuery,
  useUpdateCharacterCharactersCharacterIdPutMutation,
  useCreateCharacterCasesCaseIdCharactersPostMutation,
  useListCaseCharactersCasesCaseIdCharactersGetQuery,
  useArchiveOrganizationOrganizationsOrgIdDeleteMutation,
  useGetOrganizationOrganizationsOrgIdGetQuery,
  useUpdateOrganizationOrganizationsOrgIdPutMutation,
  useCreateOrganizationOrganizationsPostMutation,
  useListOrganizationsOrganizationsGetQuery,
  useArchiveDocumentFilesFileIdDeleteMutation,
  useGetDocumentFilesFileIdGetQuery,
  useUpdateDocumentFilesFileIdPutMutation,
  useCreateDocumentAnnotationFilesFileIdAnnotationsPostMutation,
  useGetDocumentAnnotationsFilesFileIdAnnotationsGetQuery,
  useCreateDocumentVersionFilesFileIdVersionsPostMutation,
  useGetDocumentVersionsFilesFileIdVersionsGetQuery,
  useDeleteDocumentAnnotationFilesFileIdAnnotationsAnnotationIdDeleteMutation,
  useGetDocumentExtractedTextFilesFileIdExtractedTextGetQuery,
  useGetPresignedUrlFilesPresignedUrlPostMutation,
  useListCaseDocumentsCasesCaseIdFilesGetQuery,
  useArchiveChronologyCasesCaseIdChronologyChronologyIdDeleteMutation,
  useGetChronologyCasesCaseIdChronologyChronologyIdGetQuery,
  useUpdateChronologyCasesCaseIdChronologyChronologyIdPutMutation,
  useCreateChronologyCasesCaseIdChronologyPostMutation,
  useListCaseChronologiesCasesCaseIdChronologyGetQuery,
  useCreateChronologyEventCasesCaseIdChronologyChronologyIdEventPostMutation,
  useDeleteChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdDeleteMutation,
  useUpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutMutation,
  useArchiveCaseCasesCaseIdDeleteMutation,
  useGetCaseCasesCaseIdGetQuery,
  useUpdateCaseCasesCaseIdPutMutation,
  useCreateCaseCasesPostMutation,
  useListCasesCasesGetQuery,
  useGetCaseAccessCasesCaseIdAccessGetQuery,
  useGrantCaseAccessCasesCaseIdAccessPostMutation,
  useRevokeCaseAccessCasesCaseIdAccessDeleteMutation,
  useUpdateCaseStatusCasesCaseIdStatusPutMutation,
} = injectedRtkApi;
