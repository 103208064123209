// React imports
import React from "react";
import { useEffect, useState } from "react";

// Redux imports
import { useDispatch } from "react-redux";
// import { RootState } from "../../redux/store";
import { setNewFileUrl } from "../../redux/slices/newFileUrlSlice";

// API imports
import {
  Tag,
  useGetDocumentFilesFileIdGetQuery,
  useUpdateDocumentFilesFileIdPutMutation,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Pagination,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  // SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Event, Save } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/system";

// 3rd Party imports
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

// Local imports
import TagChip from "../tags/Tag";
import AddTags from "../tags/AddTags";
import AddEventButtonOnToolbar from "../chronology/eventComponents/AddEventButtonOnToolbar";

// Styled component for the document container
const DocumentContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: "flex",
  alignItems: "center",
  height: "calc(100vh - 60px)",
  overflowY: "auto",
  paddingBottom: "20px",
  borderWidth: " 0 1px 0 0",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  flexDirection: "column",
}));

// required for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// Interface for component props
interface FileDetailsProps {
  caseId: string;
  fileId: string;
  fileName: string;
  open: boolean;
  pageNumber: number;
  onClose: () => void;
}

// Component definition
const FileDetails = ({
  fileId,
  fileName,
  open,
  pageNumber,
  caseId,
  onClose,
}: FileDetailsProps) => {
  const dispatch = useDispatch(); // Redux dispatch function

  const [numPages, setNumPages] = useState(0); // State for the number of pages in the document
  const [pageReference, setPageReference] = useState(pageNumber); // State for the current page number
  const [fileLocation, setFileLocation] = useState(""); // State for the event location
  const [editFileNames, setEditFileNames] = useState(fileName); // State for editing the file name
  const [fileDescription, setFileDescription] = useState(""); // State for the file description
  const [tags, setTags] = useState<Tag[]>([]);
  const [viewOrEdit, setViewOrEdit] = useState("view"); // State for the view or edit mode

  const [updateFile] = useUpdateDocumentFilesFileIdPutMutation(); // Mutation to update the file details

  useEffect(() => {
    setPageReference(pageNumber);
  }, [pageNumber]);

  // Fetch the document file
  const {
    data: documentFile,
    // error,
    // isLoading,
  } = useGetDocumentFilesFileIdGetQuery({
    fileId: fileId,
  });

  // Set the event location when the document file changes
  useEffect(() => {
    setFileLocation(documentFile?.url ?? "");
    setEditFileNames(documentFile?.filename ?? "");

    setFileDescription(documentFile?.description ?? "");
    setTags(documentFile?.tags ?? []);
    console.log("File: ", documentFile);
  }, [documentFile]);

  // Callback function to handle successful document load
  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    console.log(`Document loaded with ${numPages} pages`);
  };

  // Function to handle form close
  const handleClose = () => {
    onClose();
  };

  // Function to handle save
  const handleSave = async () => {
    try {
      await updateFile({
        fileId: fileId,
        updateDocumentRequest: {
          filename: editFileNames,
          description: fileDescription,
        },
      });
      dispatch(setNewFileUrl(fileLocation));
    } catch (error) {
      console.error("Error updating file:", error);
    }

    setViewOrEdit("view");
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "1200px" } }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {fileName}
          </Typography>
          <Button color="inherit" onClick={handleClose} sx={{ mr: 2 }}>
            Close
          </Button>
          {viewOrEdit === "view" ? (
            <>
              <Button
                color="inherit"
                sx={{ mr: 2 }}
                onClick={() => {
                  setViewOrEdit("edit");
                }}
              >
                Edit
              </Button>
              <AddEventButtonOnToolbar
                src={fileLocation}
                id={fileId}
                name={fileName}
                pageNumber={pageReference}
                fullButton={true}
              />
            </>
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                sx={{ mr: 2 }}
                startIcon={<Save />}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Event />}
                disabled
              >
                Add Event
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Grid2 container>
        <Grid2 xs={7}>
          <DocumentContainer>
            <Pagination
              count={numPages}
              color="primary"
              page={pageReference}
              sx={{ pt: 3, pb: 1 }}
              onChange={(event, value) => setPageReference(value)}
            />
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "2px",
                padding: "1px",
                width: 600,
              }}
            >
              <Document file={fileLocation} onLoadSuccess={onDocLoadSuccess}>
                <Page
                  pageNumber={pageReference}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={595}
                />
              </Document>
            </Box>
          </DocumentContainer>
        </Grid2>
        <Grid2 xs={5}>
          <Stack useFlexGap spacing={6} sx={{ p: 6, mt: 6 }}>
            {/* Additional content can be added here */}
            {viewOrEdit === "view" ? (
              <Typography variant="h4"> {fileName}</Typography>
            ) : (
              <TextField
                label="File Name"
                value={editFileNames}
                onChange={(e) => setEditFileNames(e.target.value)}
              />
            )}
            {viewOrEdit === "view" ? (
              <Box>
                <Typography variant="h5">Description</Typography>
                <Typography variant="body1"> {fileDescription}</Typography>
              </Box>
            ) : (
              <TextField
                label="Description"
                value={fileDescription}
                onChange={(e) => setFileDescription(e.target.value)}
                multiline
                rows={3}
              />
            )}
            {viewOrEdit === "edit" ? (
              <AddTags tags={tags} caseId={caseId} setTags={setTags} />
            ) : (
              <Stack direction="row" spacing={2} sx={{ flexWrap: "wrap" }}>
                {tags.map((tag) => (
                  <TagChip
                    key={tag.id}
                    id={Number(tag.id)}
                    color={tag.color ?? ""}
                    name={tag.name ?? ""}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </Drawer>
  );
};

export default FileDetails;
