// React imports
import React from "react";
// MUI imports
import { Box, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";

// Interface for component props
interface TagProps {
  id: number;
  name: string;
  color?: string;
  fullWidth?: boolean;
  icon?: React.ReactNode;
}

// Function to determine if a color is light
function isLightColor(color: string) {
  // color in the format '#RRGGBB'
  if (color.length === 7) {
    const rgb = [
      parseInt(color.substring(1, 3), 16),
      parseInt(color.substring(3, 5), 16),
      parseInt(color.substring(5), 16),
    ];
    const luminance =
      (0.2126 * rgb[0]) / 255 +
      (0.7152 * rgb[1]) / 255 +
      (0.0722 * rgb[2]) / 255;
    return luminance > 0.58;
  }
  return false;
}

// Styled component for the tag container
const TagContainer = styled(Box)<{
  color?: string;
  bgcolor?: string;
  borderColor?: string;
}>`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.borderColor};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Tag: React.FC<TagProps> = ({ name, color, fullWidth, icon }) => {
  const tagWidth = fullWidth ? "100%" : "auto"; // Determine the width of the tag
  const tagRightPadding = icon ? "2px" : "8px"; // Determine the right padding based on the presence of an icon
  const textColor = isLightColor(color ?? "#000000") ? "#000000" : "#ffffff"; // Determine the text color based on the background color
  const iconColor = isLightColor(color ?? "#000000")
    ? "#00000050"
    : "#ffffff70"; // Determine the icon color based on the background color
  const borderColor = isLightColor(color ?? "#000000")
    ? "#0000001F"
    : "transparent"; // Determine the border color based on the background color

  return (
    <TagContainer
      color={textColor}
      bgcolor={color ?? "#16899B"}
      borderColor={borderColor}
      sx={{ width: tagWidth, pr: tagRightPadding, pl: "8px", py: "2px" }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            textAlign: "center",
            textOverflow: "ellipsis",
            overflow: "hidden",
            flexGrow: 1,
          }}
        >
          {name}
        </Typography>
        <Box sx={{ color: iconColor, display: "flex", alignItems: "center" }}>
          {icon && icon}
        </Box>
      </Stack>
    </TagContainer>
  );
};

export default Tag;
