// React imports
import React from "react";
import { useEffect, useState, useRef } from "react";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setUpdatedEvent } from "../../../redux/slices/updatedEventSlice";

// react-pdf imports
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Pagination,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { DateTimeRangePicker } from "@mui/x-date-pickers-pro/DateTimeRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { enGB } from "date-fns/locale";
import { styled } from "@mui/system";

// API imports
import {
  Tag,
  ChronologyEvent2,
  useGetDocumentFilesFileIdGetQuery,
  useUpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutMutation,
} from "../../../api/jabsCentralApiPrivate";

// Component imports
import JoditEditor from "jodit-react";
import { wysiwygConfig } from "../../textEditor/wysiwygConfig";
import ChronologySelector from "../ChronologySelector";
import htmlToMarkdown from "../../../utils/htmlToMarkdown";
import markdownToHtmlString from "../../../utils/markdownToHtmlString";
import { parseISO } from "date-fns";
import TypeLabel from "./TypeLabel";
import Markdown from "react-markdown";
import AddTags from "../../tags/AddTags";
import TagChip from "../../tags/Tag";

// required for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// Interface for component props
interface EditEventFormProps {
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
  eventData: ChronologyEvent2; // The event data
  caseId: string; // The case ID
}

// Styled component for the document container
const DocumentContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.default,
  display: "flex",
  alignItems: "center",
  height: "calc(100vh - 60px)",
  overflowY: "auto",
  paddingBottom: "20px",
  borderWidth: " 0 1px 0 0",
  borderStyle: "solid",
  borderColor: theme.palette.divider,
  flexDirection: "column",
}));

const ViewEditEventForm: React.FC<EditEventFormProps> = ({
  open,
  onClose,
  eventData,
  caseId,
}) => {
  const dispatch = useDispatch();
  const editor = useRef(null);
  const [viewOrEdit, setViewOrEdit] = useState("view");

  const [editEvent] =
    useUpdateChronologyEventCasesCaseIdChronologyChronologyIdEventEventIdPutMutation(); // API call to update an event

  const chronologyId = useSelector(
    (state: RootState) => state.chronologySelection.selectedItem
  );
  const [numPages, setNumPages] = useState<number | undefined>(undefined); // State to store the number of pages in the PDF

  // State variables for form fields
  const [eventType, setEventType] = useState<string>(
    eventData.event_type ?? "Note"
  );
  const [eventDate, setEventDate] = useState<DateRange<Date>>([
    eventData.event_date ? parseISO(eventData.event_date) : null,
    eventData.event_date_end ? parseISO(eventData.event_date_end) : null,
  ]);
  const [eventLocation, setEventLocation] = useState(eventData.location ?? "");
  const [eventId, setEventId] = useState(eventData.id ?? "");
  const [fileId, setFileId] = useState(eventData.file_id ?? "");

  const [eventDescription, setEventDescription] = useState(
    eventData.event_description ?? ""
  );
  const [editorDescription, setEditorDescription] = useState(
    markdownToHtmlString(eventData.event_description ?? "")
  );
  const [pageReference, setPageReference] = useState(
    eventData.page_reference ? parseInt(eventData.page_reference) : 1
  );
  const [tags, setTags] = useState<Tag[]>(eventData.tags ?? []);

  // Update state variables when eventData changes
  useEffect(() => {
    setViewOrEdit("view");
    setEventId(eventData.id ?? "");
    setEventType(eventData.event_type ?? "Note");
    setEventDate([
      eventData.event_date ? parseISO(eventData.event_date) : null,
      eventData.event_date_end ? parseISO(eventData.event_date_end) : null,
    ]);
    setEventDescription(eventData.event_description ?? "");
    setEditorDescription(
      markdownToHtmlString(eventData.event_description ?? "")
    );
    setPageReference(
      eventData.page_reference ? parseInt(eventData.page_reference) : 1
    );
    setEventLocation(eventData.location ?? "");
    setFileId(eventData.file_id ?? "");
    setTags(eventData.tags ?? []);
  }, [eventData]);

  // Fetch the document file
  const { data: documentFile } = useGetDocumentFilesFileIdGetQuery({
    fileId: fileId,
  });

  // Set the event location when the document file changes
  useEffect(() => {
    setEventLocation(documentFile?.url ?? "");
  }, [documentFile]);

  // Function to handle form close
  const handleClose = () => {
    setViewOrEdit("view");
    onClose();
  };

  // Function to handle form save
  const handleSave = async () => {
    const markdownDescription = htmlToMarkdown(editorDescription ?? "");
    const evenDateStart = eventDate[0]?.toISOString();
    const eventDateEnd = eventDate[1]?.toISOString();
    const strPageReference = pageReference.toString();
    try {
      await editEvent({
        caseId: caseId,
        chronologyId: chronologyId ?? "",
        eventId: eventId,
        chronologyEventInput: {
          event_type: eventType,
          event_date: evenDateStart,
          event_date_end: eventDateEnd,
          event_description: markdownDescription,
          page_reference: strPageReference,
          location: eventLocation,
          tags: tags,
        },
      });
      dispatch(setUpdatedEvent(JSON.stringify(new Date()))); // Update the updatedEvent state to trigger a refetch
      console.log("Event updated successfully");
    } catch (error) {
      console.error("Error updating event: ", error);
    }
    handleClose();
  };

  // Callback function to handle successful document load
  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    console.log(`Document loaded with ${numPages} pages`);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "1200px" } }}
    >
      <AppBar position="sticky">
        <Toolbar>
          {viewOrEdit === "view" ? (
            <>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Event
              </Typography>
              <Button color="inherit" onClick={handleClose} sx={{ mr: 2 }}>
                Close
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => setViewOrEdit("edit")}
                sx={{ mr: 2 }}
              >
                Edit
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Edit Event
              </Typography>
              <Button color="inherit" onClick={handleClose} sx={{ mr: 2 }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                color="secondary"
              >
                Save
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Grid2 container>
        <Grid2 xs={6}>
          <DocumentContainer>
            <Pagination
              count={numPages}
              color="primary"
              page={pageReference}
              sx={{ pt: 3, pb: 1 }}
              onChange={(event, value) => setPageReference(value)}
            />
            <Box
              sx={{
                border: "1px solid #E0E0E0",
                borderRadius: "2px",
                padding: "1px",
                width: 560,
              }}
            >
              <Document file={eventLocation} onLoadSuccess={onDocLoadSuccess}>
                <Page
                  pageNumber={pageReference}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  width={550}
                />
              </Document>
            </Box>
          </DocumentContainer>
        </Grid2>
        <Grid2 xs={6}>
          <Stack useFlexGap spacing={6} sx={{ p: 6 }}>
            {viewOrEdit === "view" ? null : <ChronologySelector />}
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              {viewOrEdit === "view" ? (
                <Typography variant="h5">
                  {eventType && <TypeLabel type={eventType} />}
                </Typography>
              ) : (
                <Box>
                  <FormControl sx={{ width: "150px" }}>
                    <InputLabel id="event-type">Type</InputLabel>
                    <Select
                      labelId="event-type"
                      value={eventType}
                      label="Event Type"
                      onChange={(event: SelectChangeEvent) => {
                        setEventType(event.target.value as string);
                      }}
                      variant="outlined"
                    >
                      <MenuItem value="Note">Note</MenuItem>
                      <MenuItem value="Meeting">Meeting</MenuItem>
                      <MenuItem value="Consultation">Consultation</MenuItem>
                      <MenuItem value="Observation">Observation</MenuItem>
                      <MenuItem value="Appointment">Appointment</MenuItem>
                      <MenuItem value="Procedure">Procedure</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
              {viewOrEdit === "view" ? (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography variant="h6" sx={{}}>
                    {eventDate[0] ? eventDate[0].toLocaleString() : "--"}
                  </Typography>
                  <Typography variant="h6" sx={{}}>
                    to
                  </Typography>
                  <Typography variant="h6" sx={{}}>
                    {eventDate[1] ? eventDate[1].toLocaleString() : "N/A"}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enGB}
                  >
                    <DateTimeRangePicker
                      localeText={{ start: "Event start", end: "Event end" }}
                      value={eventDate}
                      onChange={(newValue) => {
                        setEventDate(newValue);
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              )}
            </Stack>
            {viewOrEdit === "edit" ? (
              <AddTags tags={tags} caseId={caseId} setTags={setTags} />
            ) : (
              <Stack direction="row" spacing={2} sx={{ flexWrap: "wrap" }}>
                {tags.map((tag) => (
                  <TagChip
                    key={tag.id}
                    id={Number(tag.id)}
                    color={tag.color ?? ""}
                    name={tag.name ?? ""}
                  />
                ))}
              </Stack>
            )}
            {viewOrEdit === "view" ? (
              <Box>
                <Typography variant="h5" sx={{ pt: 7, fontWeight: 700 }}>
                  Description
                </Typography>
                <Markdown>{eventDescription}</Markdown>
              </Box>
            ) : (
              <Box>
                <Typography
                  variant="overline"
                  color="GrayText"
                  sx={{ lineHeight: 1, textTransform: "capitalize", ml: 3 }}
                >
                  Description
                </Typography>
                <JoditEditor
                  ref={editor}
                  value={editorDescription}
                  config={wysiwygConfig}
                  tabIndex={1}
                  onBlur={(newContent) => setEditorDescription(newContent)}
                />
              </Box>
            )}
            {viewOrEdit === "view" ? null : (
              <TextField
                label="Page Reference"
                value={pageReference}
                onChange={(e) => setPageReference(parseInt(e.target.value))}
                fullWidth
              />
            )}
          </Stack>
        </Grid2>
      </Grid2>
    </Drawer>
  );
};

export default ViewEditEventForm;
