// React imports
import React from "react";

// Material-UI imports
import styled from "@emotion/styled";

import { Box } from "@mui/material";

// Custom components
import Spacer from "../navbar/appBar/Spacer";
import SidelightFooterLogo from "../../vendor/SidelightFooterLogo";

const Wrapper = styled(Box)`
  flex-shrink: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;

  background-color: ${(props) => props.theme.palette.grey[900]};
`;

const LogoContainer = styled(Box)`
  width: 195px;
  padding-right: 5px;
  display: flex;
  align-items: center;
`;

const Footer = () => {
  return (
    <Wrapper>
      <LogoContainer>
        <SidelightFooterLogo />
      </LogoContainer>
      <Spacer />
    </Wrapper>
  );
};

export default Footer;
