// React imports
import React from "react";
import { useParams } from "react-router-dom";

// Local imports

import CharacterTableCard from "../../../components/characterList/CharacterTableCard";

const SelectedPatientCharactersPanel: React.FC = () => {
  const selectedCase = useParams<{ caseId: string }>().caseId;

  return (
    <div>
      <CharacterTableCard caseId={selectedCase || ""} />
    </div>
  );
};

export default SelectedPatientCharactersPanel;
