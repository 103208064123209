// react imports
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Redux imports
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCase } from "../../redux/slices/selectedCaseSlice";
import { RootState } from "../../redux/store";

// MUI imports
import { Box } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  // GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

// API imports
import {
  Case,
  useListCasesCasesGetQuery,
} from "../../api/jabsCentralApiPrivate";

// local imports
import Spacer from "../navbar/appBar/Spacer";
import Markdown from "react-markdown";

// custom box for aligning cell content vertically
const CentralVertical = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
`;

// Custom toolbar component for the case table
const CustomToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{ pt: 2, pb: 2, borderBottom: "1px solid #E0E0E0" }}
    >
      <Spacer />
      <GridToolbarQuickFilter sx={{ mr: "10px" }} />
      <Box sx={{ display: "flex", gap: 2 }}>
        {/* <GridToolbarExport
          slotProps={{
            button: { variant: "outlined" },
          }}
        /> */}
        <GridToolbarFilterButton
          slotProps={{
            button: { variant: "outlined" },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
};

function CaseTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<Case[]>([]);

  // Selector to get the updated cases from the Redux store
  const updatedCases = useSelector((state: RootState) => state.updatedCases);

  // Fetch the list of cases using the custom hook
  const {
    data: listCases,
    error: listCasesError,
    isLoading: isListCasesLoading,
    isFetching: isListCasesFetching,
    refetch: refetchListCases,
  } = useListCasesCasesGetQuery({
    status: "active",
  });

  // Handle row click event to navigate to the case details page and set the selected case in the Redux store
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/case/${params.id}`);
    dispatch(setSelectedCase(params.id.toString()));
  };

  // Refetch the list of cases when the updatedCases changes
  useEffect(() => {
    refetchListCases();

    setRowData(listCases?.data ?? []);
  }, [updatedCases, refetchListCases, listCases, dispatch]);

  return (
    <React.Fragment>
      {listCasesError && <div>{JSON.stringify(listCasesError)}</div>}

      <DataGridPro
        loading={isListCasesLoading || isListCasesFetching} // Set the loading state of the DataGridPro
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
        onRowClick={handleRowClick}
        getRowHeight={() => "auto"} // Set the row height to auto
        disableColumnSelector // Disable the column selector
        disableDensitySelector // Disable the density selector
        checkboxSelection
        disableRowSelectionOnClick // Disable row selection on click
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          pt: 1,
          border: "none",
          backgroundColor: "white",
          [`& .${gridClasses.cell}`]: {
            pt: 2,
            pb: 2,
            pr: 3,
            pl: 3,
          },
        }}
        initialState={{
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          sorting: { sortModel: [{ field: "start_date", sort: "desc" }] }, // Set the sorting column
          density: "compact", // Set the density to comfortable
        }}
        rows={rowData} // Set the rows of the DataGridPro to the list of cases
        columnHeaderHeight={60}
        columns={[
          // Define the columns for the DataGridPro
          // {
          //   field: "id",
          //   headerName: "Case ID",
          //   width: 210,
          // },
          {
            field: "start_date",
            headerName: "Start Date",
            width: 150,
            type: "date",
            renderCell: (params) => (
              <CentralVertical>
                {params.value
                  ? new Date(params.value).toLocaleDateString()
                  : "N/A"}
              </CentralVertical>
            ),
            valueGetter: (params) => {
              return params ? new Date(params) : null;
            },
          },
          {
            field: "title",
            headerName: "Title",
            renderCell: (params) => (
              <CentralVertical
                sx={{
                  fontWeight: 600,
                }}
              >
                {params.value as string}
              </CentralVertical>
            ),
            flex: 2,
            minWidth: 150,
          },
          {
            field: "description",
            headerName: "Description",
            renderCell: (params) => (
              <CentralVertical>
                <Box>
                  <Markdown>{params.value as string}</Markdown>
                </Box>
              </CentralVertical>
            ),
            flex: 3,
            minWidth: 150,
          },
          {
            field: "case_status",
            headerName: "Status",
            renderCell: (params) => (
              <CentralVertical>{params.value as string}</CentralVertical>
            ),
            width: 120,
          },
          {
            field: "case_type",
            headerName: "Type",
            renderCell: (params) => (
              <CentralVertical>{params.value as string}</CentralVertical>
            ),
            width: 120,
          },
          {
            field: "created_at",
            headerName: "Date Created",
            width: 150,
            type: "date",
            renderCell: (params) => (
              <CentralVertical>
                {params.value
                  ? new Date(params.value).toLocaleDateString()
                  : "N/A"}
              </CentralVertical>
            ),
            valueGetter: (params) => {
              return params ? new Date(params) : null;
            },
          },
        ]}
      />
    </React.Fragment>
  );
}

export default CaseTable;
