export const Permissions = {
  // User permissions
  USERS_READ: "users:read",
  USERS_MANAGE: "users:manage",

  // Case permissions
  CASE_READ: "case:read",
  CASE_MANAGE: "case:manage",

  // Document permissions
  DOCUMENT_READ: "document:read",
  DOCUMENT_UPLOAD: "document:upload",
  DOCUMENT_MANAGE: "document:manage",
} as const;

// Type for all permission values
export type Permission = (typeof Permissions)[keyof typeof Permissions];

// Helper function to check if a string is a valid permission
export const isValidPermission = (
  permission: string
): permission is Permission => {
  return Object.values(Permissions).includes(permission as Permission);
};
