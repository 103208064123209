// React imports
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";

// Redux imports
import { useSelector, useDispatch } from "react-redux";
import { setSelectedFileItem } from "../../redux/slices/fileSlice";
import { RootState } from "../../redux/store";

// API imports
import {
  Document,
  useListCaseDocumentsCasesCaseIdFilesGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Box } from "@mui/material";
import {
  DataGridPro,
  GridFilterModel,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

// Local imports
import SingleFileUploader from "./SingleFileUploader";
import Spacer from "../navbar/appBar/Spacer";

// Props interface for the file table component
interface FileListProps {
  caseId: string;
  characterId: string;
}

// Custom toolbar component for the file table
const CustomToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{ pt: 2, pb: 2, borderBottom: "1px solid #E0E0E0" }}
    >
      <SingleFileUploader />
      <Spacer />
      <GridToolbarQuickFilter sx={{ mr: "10px" }} />
      <Box sx={{ display: "flex", gap: 2 }}>
        <GridToolbarExport
          slotProps={{
            button: { variant: "outlined" },
          }}
        />
        <GridToolbarFilterButton
          slotProps={{
            button: { variant: "outlined" },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );
};

const FileTable: React.FC<FileListProps> = ({ caseId, characterId }) => {
  // const navigate = useNavigate();

  // Listen for new file added from the Redux store
  const updatedFiles = useSelector((state: RootState) => state.newFileUrl);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState<Document[]>([]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: "character_id",
        operator: "equals",
        value: characterId,
      },
    ],
  });

  // Fetch list of cases
  const {
    data: listFiles,
    error: listFilesError,
    isLoading: isListFilesLoading,
    // isFetching: isListFilesFetching,
    refetch: refetchListFiles,
  } = useListCaseDocumentsCasesCaseIdFilesGetQuery(
    {
      caseId: caseId,
      limit: 1000,
    },
    {
      pollingInterval: 60000,
    }
  );

  // Handle row click event to navigate to the case details page and set the selected case in the Redux store
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    console.log("Row clicked: ", params);
    dispatch(setSelectedFileItem(params.id.toString()));
    // navigate(`/file/${params.id}`);
  };

  // Refetch the list of files when a new file is added
  useEffect(() => {
    refetchListFiles();
    setRowData(listFiles?.data ?? []);
  }, [updatedFiles, refetchListFiles, listFiles, dispatch]);

  return (
    <React.Fragment>
      {listFilesError && <div>{JSON.stringify(listFilesError)}</div>}
      <DataGridPro
        checkboxSelection
        columns={[
          // { field: "id", headerName: "ID", width: 210 },
          { field: "character_id", headerName: "Character ID", width: 210 },
          { field: "filename", headerName: "Name", flex: 2, minWidth: 150 },
          {
            field: "processing_status",
            headerName: "Status",
            width: 100,
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 150,
          },
          { field: "type", headerName: "Type", width: 130 },
          {
            field: "created_at",
            headerName: "Created At",
            flex: 1,
            minWidth: 150,
          },
        ]}
        columnHeaderHeight={40}
        disableColumnSelector // Disable the column selector
        disableDensitySelector // Disable the density selector
        disableRowSelectionOnClick // Disable row selection on click
        initialState={{
          filter: {
            filterModel: filterModel,
          }, // Set the filter
          pinnedColumns: {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          }, // Set the pinned columns
          sorting: { sortModel: [{ field: "createdAt", sort: "asc" }] }, // Set the sorting column
          density: "standard", // Set the density to standard
        }}
        loading={isListFilesLoading} // Set the loading state of the DataGridPro
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowClick={handleRowClick}
        pagination
        rows={rowData} // Set the rows of the DataGridPro to the list of cases
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        sx={{
          pt: 1,
          border: "none",
          backgroundColor: "white",
        }}
      />
    </React.Fragment>
  );
};

export default FileTable;
