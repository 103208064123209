// React imports
import React from "react";
import { useState } from "react";

// react-pdf imports
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";

// MUI imports
import {
  AppBar,
  Box,
  Pagination,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";

// Local imports
// import AddEventButtonOnToolbar from "../chronology/eventComponents/AddEventButtonOnToolbar";
import FileDetailsButton from "../files/FileDetailsButton";

// required for react-pdf to work
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

interface ThumbnailViewerProps {
  id: string;
  src: string | undefined;
  name?: string;
}

export default function ThumbnailViewer({
  id,
  src,
  name,
}: ThumbnailViewerProps) {
  const link = src ? src : ""; // Use the provided src or an empty string if not provided
  const linkTitle = name ? name : link.split("/").pop(); // Use the provided name or extract the file name from the link
  let content = null;

  const [numPages, setNumPages] = useState<number | undefined>(undefined); // State to store the number of pages in the PDF
  const [pageNumber, setPageNumber] = useState(1); // State to store the current page number

  // Callback function to handle successful document load
  const onDocLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1);
    console.log(`Document loaded with ${numPages} pages`);
  };

  // Function to handle click event
  const handleClick = () => {
    window.open(`/file/${id}`, "_blank");
  };

  // Check if the link is an image (jpg or png)
  if (link.includes(".jpg") || link.includes(".png")) {
    content = (
      <Box sx={{ width: "400px" }}>
        <img src={link} alt="thumbnail" style={{ maxWidth: "100%" }} />
      </Box>
    );
  } else if (link.includes(".pdf")) {
    // Check if the link is a PDF
    content = (
      <Box
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: "2px",
          padding: "1px",
        }}
      >
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, overflowWrap: "anywhere" }}
            >
              {linkTitle}
            </Typography>
            {/* <AddEventButtonOnToolbar
              id={id}
              src={src}
              name={name}
              pageNumber={pageNumber}
            /> */}
            <FileDetailsButton id={id} name={name} pageNumber={pageNumber} />
          </Toolbar>
        </AppBar>
        <Stack direction="row" justifyContent="center">
          <Pagination
            count={numPages}
            page={pageNumber}
            onChange={(event, value) => setPageNumber(value)}
            sx={{ p: 2 }}
            color="primary"
          />
        </Stack>
        <Document file={link} onLoadSuccess={onDocLoadSuccess}>
          <Page
            width={400}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            onClick={handleClick}
          />
        </Document>
      </Box>
    );
  } else {
    // If the file format is not supported
    content = (
      <Box>
        <h3>File format not supported</h3>
      </Box>
    );
  }

  return content;
}
