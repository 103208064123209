import React from "react";
import { renderToString } from "react-dom/server";
import Markdown from "react-markdown";

const markdownToHtmlString = (markdownText: string) => {
  const htmlString = renderToString(<Markdown>{markdownText}</Markdown>);
  return htmlString;
};

export default markdownToHtmlString;
