// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import { Button } from "@mui/material";
import { Event } from "@mui/icons-material";

// Component imports
import AddChronologyForm from "./AddChronologyForm";

// Interface for component props
interface AddChronologyButtonProps {
  caseId: string; // ID of the case to which the chronology belongs

  patientId: string;
}

// Component definition
function AddChronologyButton({ caseId, patientId }: AddChronologyButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<Event />}
        onClick={() => setOpen(true)}
      >
        Add Chronology
      </Button>

      <AddChronologyForm
        open={open}
        onClose={() => setOpen(false)}
        caseId={caseId}
        patientId={patientId}
      />
    </React.Fragment>
  );
}

export default AddChronologyButton;
