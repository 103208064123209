// Markdown imports
import { remark } from "remark";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";

// Convert HTML to Markdown
const htmlToMarkdown = (htmlText: string) => {
  const file = remark()
    .use(rehypeParse, { fragment: true }) // Use rehype-parse to parse HTML
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(htmlText);

  return String(file);
};

export default htmlToMarkdown;
