// React imports
import React from "react";

// Material-UI imports
import { Box } from "@mui/material";
import styled from "@emotion/styled";

// Local imports
import CharacterTable from "./CharacterTable";

interface CharacterTableCardProps {
  caseId: string;
}

const CharacterTableCardContainer = styled(Box)`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows[1]};
  background-color: white;
  padding: 0px 12px 0 12px;
  height: calc(100vh - 200px);
`;

const CharacterTableCard: React.FC<CharacterTableCardProps> = ({ caseId }) => {
  return (
    <>
      <CharacterTableCardContainer>
        <CharacterTable caseId={caseId} />
      </CharacterTableCardContainer>
    </>
  );
};

export default CharacterTableCard;
