// React imports
import React from "react";

// import { useEffect } from "react";

// Redux imports
// import { useSelector } from "react-redux";
// import { RootState } from "../../../redux/store";

// API imports
import { useGetDocumentFilesFileIdGetQuery } from "../../../api/jabsCentralApiPrivate";

// MUI imports
import { Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Description } from "@mui/icons-material";

// Component imports
import Loader from "../../loaders/Loader";
import ThumbnailViewer from "../../pdf/Thumbnail";
// import { Thumbnail } from "react-pdf";

// const Wordwrap = styled.pre`
//   white-space: pre-wrap;
//   word-wrap: break-word;
// `;
const Picker = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 440px;
  height: calc(100vh - 200px);
  background-color: white;
  border: 1px solid ${(props) => props.theme.palette.divider};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.grey[300]};
`;

interface GetFileProps {
  fileId: string;
}

const GetFile: React.FC<GetFileProps> = ({ fileId }) => {
  //   const newFileSelected = useSelector(
  //     (state: RootState) => state.fileSelection.selectedItem
  //   );
  // Fetch file data
  const {
    data: fileData,
    error: fileError,
    isLoading: isFileLoading,
    isFetching: isFileFetching,
    // refetch: refetchFile,
  } = useGetDocumentFilesFileIdGetQuery({
    fileId: fileId,
    // fileId: "67add49f336d78dfad069f04",
  });

  // Refetch the file data when fileId changes
  //   useEffect(() => {
  //     refetchFile();
  //   }, [newFileSelected, refetchFile]);

  // const handleClick = () => {
  //   window.open(`/file/${fileData?.id}`, "_blank");
  // };

  let content = null;

  if (fileId === "0") {
    content = (
      <Picker>
        <Description sx={{ fontSize: 100 }} />
        <Typography variant="h4" sx={{ width: "200px", textAlign: "center" }}>
          Select a file from the table on the left
        </Typography>
      </Picker>
    );
  } else if (isFileLoading) {
    // Display loader while data is loading
    content = <Loader />;
  } else if (isFileFetching) {
    // Display loader while data is fetching
    content = <Loader />;
  } else if (fileError) {
    // Display error message if there is an error fetching data
    console.log("fileError: ", fileError);
    content = (
      <Typography variant="body1">
        {JSON.stringify("Error fetching file data", null, 2)}
      </Typography>
    );
  } else if (fileData) {
    // Display file data if available
    content = (
      <>
        <ThumbnailViewer
          src={fileData?.url ? fileData.url : ""}
          name={fileData?.filename ? fileData.filename : ""}
          id={fileData?.id ? fileData.id : ""}
        />
        {/* <Wordwrap>
          <Typography variant="body1">
            {JSON.stringify(fileData, null, 2)}
          </Typography>
        </Wordwrap> */}
      </>
    );
  }

  return <div>{content}</div>;
};

export default GetFile;
