// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import { Button } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";

//Component imports
import AddCharacterForm from "./AddCharacterForm";

interface AddCharacterButtonProps {
  caseId: string; // ID of the case to which the character belongs
}

function AddCharacterButton({ caseId }: AddCharacterButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<PersonAdd />}
        onClick={() => setOpen(true)}
      >
        Add Character
      </Button>

      <AddCharacterForm
        open={open}
        onClose={() => setOpen(false)}
        caseId={caseId}
      />
    </React.Fragment>
  );
}

export default AddCharacterButton;
