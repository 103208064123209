// React imports
import React from "react";
import { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";

// Redux imports
// import { useSelector, useDispatch } from "react-redux";
// import { RootState } from "../../redux/store";

// API imports
import {
  BaseTimelineEvent,
  useGetCharacterTimelineCharactersCharacterIdTimelineGetQuery,
} from "../../api/jabsCentralApiPrivate";

// MUI imports
import { Box } from "@mui/material";
import {
  DataGridPro,
  gridClasses,
  //   GridEventListener,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  //   GridToolbarExport,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";
import styled from "@emotion/styled";

// 3rd Party imports
import Markdown from "react-markdown";

// Local imports
import Spacer from "../navbar/appBar/Spacer";

// Interface for component props
interface TimelineTableProps {
  characterId: string;
}

const CardContainer = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  boxShadow: theme.shadows[1],
  backgroundColor: theme.palette.background.paper,
  padding: "0px 12px 0 12px",
  height: "calc(100vh - 200px)",
}));

// custom box for aligning cell content vertically
const CentralVertical = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
`;

// Component definition
const TimelineTable: React.FC<TimelineTableProps> = ({ characterId }) => {
  const [rowData, setRowData] = useState<BaseTimelineEvent[]>([]);
  // Fetch character timeline
  const {
    data: timeline,
    error: timelineError,
    isLoading: isTimelineLoading,
    isFetching: isTimelineFetching,
    // refetch: refetchTimeline,
  } = useGetCharacterTimelineCharactersCharacterIdTimelineGetQuery({
    characterId: characterId,
    limit: 1000,
  });

  useEffect(() => {
    if (timeline) {
      setRowData(timeline.data ?? []);
    }
  }, [timeline]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        sx={{ pt: 2, pb: 2, borderBottom: "1px solid #E0E0E0" }}
      >
        <Spacer />
        <GridToolbarQuickFilter />
        <Box sx={{ display: "flex", gap: 2 }}>
          <GridToolbarFilterButton
            slotProps={{
              button: { variant: "outlined" },
            }}
          />
        </Box>
      </GridToolbarContainer>
    );
  };
  return (
    <>
      {timelineError && <div>{JSON.stringify(timelineError)}</div>}
      <CardContainer>
        {/* Add your content here */}
        <DataGridPro
          //   autoPageSize
          checkboxSelection
          columns={[
            {
              field: "begin_date",
              headerName: "Start date",
              width: 180,
              type: "date",
              renderCell: (params) => (
                <CentralVertical>
                  {params.value
                    ? new Date(params.value).toLocaleString()
                    : "N/A"}
                </CentralVertical>
              ),
              valueGetter: (params) => {
                return params ? new Date(params) : null;
              },
            },
            {
              field: "end_date",
              headerName: "End date",
              width: 180,
              type: "date",
              renderCell: (params) => (
                <CentralVertical>
                  {params.value
                    ? new Date(params.value).toLocaleString()
                    : "N/A"}
                </CentralVertical>
              ),
              valueGetter: (params) => {
                return params ? new Date(params) : null;
              },
            },
            {
              field: "event_type",
              headerName: "Type",
              width: 180,
              renderCell: (params) => (
                <CentralVertical>{params.value as string}</CentralVertical>
              ),
            },
            {
              field: "description",
              headerName: "Description",
              flex: 2,
              minWidth: 200,
              renderCell: (params) => (
                <CentralVertical>
                  <Box>
                    <Markdown>{params.value as string}</Markdown>
                  </Box>
                </CentralVertical>
              ),
            },
            {
              field: "status",
              headerName: "Status",
              width: 120,
              renderCell: (params) => (
                <CentralVertical>{params.value as string}</CentralVertical>
              ),
            },
          ]}
          disableRowSelectionOnClick // Disable row selection on click
          getRowHeight={() => "auto"} // Set the row height to auto
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
            }, // Set the pinned columns
            sorting: { sortModel: [{ field: "start_date", sort: "desc" }] }, // Set the sorting column
            density: "standard", // Set the density to standard
          }}
          loading={isTimelineLoading || isTimelineFetching}
          pageSizeOptions={[50, 100, 250, 500]}
          pagination
          rows={rowData}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "skeleton",
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          sx={{
            pt: 1,
            border: "none",
            backgroundColor: "white",
            [`& .${gridClasses.cell}`]: {
              pt: 2,
              pb: 2,
              pr: 3,
              pl: 3,
            },
          }}
        />
      </CardContainer>
    </>
  );
};

// Export the component
export default TimelineTable;
