// React imports
import React from "react";
import { useEffect, useState } from "react";

// MUI imports
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Pending, LocalOffer } from "@mui/icons-material";
import styled from "@emotion/styled";

// API imports
import {
  Tag,
  useGetCaseCasesCaseIdGetQuery,
  useUpdateCaseCasesCaseIdPutMutation,
} from "../../api/jabsCentralApiPrivate";

// 3rd party components
import { HexColorPicker } from "react-colorful";
import "./color-picker.css";

// Local imports
import TagComponent from "./Tag";
import Loader from "../loaders/Loader";

// Interface for component props
interface TagManagerProps {
  iconButton: boolean; // Indicates if the component should be rendered as an icon button
  caseId: string; // The ID of the case
}

// Styled component for the tag container
const TagContainer = styled(Box)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: "0 0 0 1px",
  borderColor: theme.palette.divider,
  height: "100%",
  maxHeight: "calc(100vh - 160px)",
  overflowY: "auto",
  padding: "16px ",
}));

// Function to determine if a color is light
function isLightColor(color: string) {
  if (color.length === 7) {
    const rgb = [
      parseInt(color.substring(1, 3), 16),
      parseInt(color.substring(3, 5), 16),
      parseInt(color.substring(5), 16),
    ];
    const luminance =
      (0.2126 * rgb[0]) / 255 +
      (0.7152 * rgb[1]) / 255 +
      (0.0722 * rgb[2]) / 255;
    return luminance > 0.58;
  }
  return false;
}

const TagManager: React.FC<TagManagerProps> = ({ iconButton, caseId }) => {
  const [open, setOpen] = useState(false); // State to control the dialog open/close
  const [tags, setTags] = useState<Tag[]>([]); // State to store the list of tags
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null); // State to store the selected tag
  const [countTransactions, setCountTransactions] = useState(0); // State to count the number of transactions
  const [unsavedChanges, setUnsavedChanges] = useState(false); // State to decide whether to warn about unsaved changes

  // State for form fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState("#16899B");

  const [updateCase] = useUpdateCaseCasesCaseIdPutMutation(); // API call to update a case

  const {
    data: caseData,
    error: caseError,
    isLoading: isCaseLoading,
    isFetching: isCaseFetching,
  } = useGetCaseCasesCaseIdGetQuery({ caseId: caseId });

  // Update tags when caseData changes
  useEffect(() => {
    if (caseData) {
      setTags(caseData.tags ?? []);
    }
  }, [caseData]);

  // Function to handle adding a new tag
  const handleAddTag = () => {
    const strId =
      tags.length > 0 ? parseInt(tags[tags.length - 1].id ?? "0") + 1 : 1;
    const updatedTags = [
      ...tags,
      {
        id: strId.toString(),
        name: name,
        description: description,
        color: color,
      },
    ];
    setTags(updatedTags);
    handleRefreshForm();
    setCountTransactions(countTransactions + 1);
  };

  // Function to handle updating an existing tag
  const handleUpdateTag = () => {
    if (selectedTag) {
      const updatedTags = tags.map((tag) =>
        tag.id === selectedTag.id
          ? {
              ...tag,
              name: name,
              description: description,
              color: color,
            }
          : tag
      );
      setTags(updatedTags);
      handleRefreshForm();
      setCountTransactions(countTransactions + 1);
    }
  };

  // Function to handle selecting a tag
  const handleSelectTag = (tag: Tag) => {
    setSelectedTag(tag);
    setName(tag.name ?? "");
    setDescription(tag.description ?? "");
    setColor(tag.color ?? "#16899B");
  };

  // Function to refresh the form fields
  const handleRefreshForm = () => {
    setSelectedTag(null);
    setName("");
    setDescription("");
    setColor("#16899B");
  };

  // Function to handle deleting a tag
  const handleDeleteTag = (id: string) => {
    const updatedTags = tags.filter((tag) => tag.id !== id);
    setTags(updatedTags);
    setCountTransactions(countTransactions + 1);
    handleRefreshForm();
  };

  // Function to handle saving the tags
  const handleSave = async () => {
    try {
      await updateCase({
        caseId: caseId,
        case: {
          title: caseData?.title ?? "",
          case_type: caseData?.case_type ?? "",
          case_status: caseData?.case_status ?? "",
          start_date: caseData?.start_date ?? "",
          tags: tags,
        },
      });
      setCountTransactions(0);
      handleClose();
    } catch (error) {
      console.error("Error updating tags: ", error);
    }
  };

  // Function to handle closing the dialog
  const handleClose = () => {
    setOpen(false);
    setUnsavedChanges(false);
    setCountTransactions(0);
    handleRefreshForm();
  };

  // function to check whether there are unsaved changes when the dialog is closed
  const checkClose = () => {
    if (countTransactions > 0) {
      setUnsavedChanges(true);
    } else {
      handleClose();
    }
  };

  return (
    <>
      {iconButton ? (
        <IconButton onClick={() => setOpen(true)}>
          <LocalOffer />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          startIcon={<LocalOffer />}
          onClick={() => setOpen(true)}
        >
          Manage Tags
        </Button>
      )}
      <Dialog open={open} onClose={checkClose} fullWidth maxWidth="sm">
        <DialogTitle sx={{ fontSize: "1.2rem", fontWeight: "700" }}>
          Tag Manager
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <Grid2 container>
            <Grid2 xs={7}>
              <Stack spacing={4} sx={{ p: 6 }}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 3, mb: 3 }}
                  multiline
                  rows={3}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <HexColorPicker
                  color={color}
                  onChange={setColor}
                  className="color-picker"
                />
                {selectedTag ? (
                  <>
                    <Stack
                      useFlexGap
                      direction="row"
                      spacing={2}
                      sx={{ mt: 3, flexWrap: "wrap" }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleUpdateTag}
                        sx={{
                          backgroundColor: color,
                          color: isLightColor(color) ? "black" : "white",
                          flexGrow: 1,
                        }}
                      >
                        Update
                      </Button>
                      <Button variant="outlined" onClick={handleRefreshForm}>
                        New Tag
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() =>
                          selectedTag &&
                          selectedTag.id &&
                          handleDeleteTag(selectedTag.id)
                        }
                      >
                        Delete
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    onClick={handleAddTag}
                    fullWidth
                    sx={{
                      mt: 3,
                      backgroundColor: color,
                      color: isLightColor(color) ? "black" : "white",
                    }}
                  >
                    Add Tag
                  </Button>
                )}
              </Stack>
            </Grid2>
            <Grid2 xs={5}>
              <TagContainer>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  {isCaseLoading || isCaseFetching ? <Loader /> : null}
                  {caseError ? (
                    <Typography variant="body1" color="error">
                      Error fetching tags
                    </Typography>
                  ) : null}
                  {tags.map((tag) => (
                    <Box key={tag.id} onClick={() => handleSelectTag(tag)}>
                      <TagComponent
                        id={Number(tag.id)}
                        name={tag.name ?? ""}
                        color={tag.color ?? "#FFFFFF"}
                        icon={<Pending />}
                      />
                    </Box>
                  ))}
                </Stack>
              </TagContainer>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          {unsavedChanges ? (
            <>
              <Alert severity="warning" sx={{ flexGrow: 1 }}>
                Unsaved changes, do you want to close without saving?
              </Alert>
              <Button onClick={handleClose}>Yes, Close</Button>
            </>
          ) : (
            <Button onClick={checkClose}>Close</Button>
          )}
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagManager;
