// React imports
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

// MUI imports
import { IconButton, Tooltip } from "@mui/material";
import { FileOpen } from "@mui/icons-material";

// Local imports
import FileDetails from "./FileDetails";

// Interface for component props
interface FileDetailsButtonProps {
  id: string;
  name?: string;
  pageNumber: number;
}

// Component definition
function FileDetailsButton({ id, name, pageNumber }: FileDetailsButtonProps) {
  const [open, setOpen] = useState(false);
  const caseId = useParams<{ caseId: string }>().caseId || "";
  console.log("open: ", open);

  return (
    <React.Fragment>
      <Tooltip title="File Details">
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="File Details"
          onClick={() => setOpen(true)}
        >
          <FileOpen />
        </IconButton>
      </Tooltip>

      <FileDetails
        open={open}
        onClose={() => setOpen(false)}
        caseId={caseId}
        fileId={id}
        fileName={name || ""}
        pageNumber={pageNumber}
      />
    </React.Fragment>
  );
}

export default FileDetailsButton;
