// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";

//Component imports
import EditCaseForm from "./EditCaseForm";

//API imports
import { Case } from "../../api/jabsCentralApiPrivate";

interface EditCaseButtonProps {
  caseData: Case;
}

function EditCaseButton({ caseData }: EditCaseButtonProps) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Edit />}
        onClick={() => setOpen(true)}
      >
        Edit Case
      </Button>
      {/* <Drawer anchor="right" open={open} onClose={() => setOpen(false)}> */}
      <EditCaseForm
        open={open}
        onClose={() => setOpen(false)}
        caseData={caseData}
      />
      {/* </Drawer> */}
    </React.Fragment>
  );
}

export default EditCaseButton;
