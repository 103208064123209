import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { jabsCentralApiPrivate } from "../api/jabsCentralApiPrivate";
import { jabsCentralApiPublic } from "../api/jabsCentralApiPublic";
import fileSelectionReducer from "./slices/fileSlice";
import eventReducer from "./slices/event";
import patientSelectionReducer from "./slices/selectedPatientSlice";
import updatedCasesReducer from "./slices/updatedCasesSlice";
import updatedCharactersReducer from "./slices/updatedCharactersSlice";
import updatedEventsReducer from "./slices/updatedEventSlice";
import updatedTagsReducer from "./slices/updatedTagSlice";
import caseSelectionReducer from "./slices/selectedCaseSlice";
import chronologySelectionReducer from "./slices/selectedChronologySlice";
import newFileUrlReducer from "./slices/newFileUrlSlice";
import changeMockTimelineReducer from "./slices/mockTimelineSlice";
import selectedEventReducer from "./slices/selectedEventSlice";

const store = configureStore({
  reducer: {
    [jabsCentralApiPrivate.reducerPath]: jabsCentralApiPrivate.reducer,
    [jabsCentralApiPublic.reducerPath]: jabsCentralApiPublic.reducer,
    fileSelection: fileSelectionReducer,
    event: eventReducer,
    patientSelection: patientSelectionReducer,
    updatedCases: updatedCasesReducer,
    updatedCharacters: updatedCharactersReducer,
    updatedEvents: updatedEventsReducer,
    updatedTags: updatedTagsReducer,
    caseSelection: caseSelectionReducer,
    chronologySelection: chronologySelectionReducer,
    newFileUrl: newFileUrlReducer,
    changeMockTimeline: changeMockTimelineReducer,
    selectedEvent: selectedEventReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(jabsCentralApiPrivate.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
