export const wysiwygConfig = {
  readonly: false, // all options from https://xdsoft.net/jodit/docs/,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  defaultActionOnPaste: "insert_clear_html" as "insert_clear_html",
  placeholder: "Start typing...",
  toolbarAdaptive: false,
  disablePlugins: [
    "about",
    "color",
    "font",
    "image",
    "line-height",
    "powered-by-jodit",
    "print",
    "source",
    "video",
    "table",
    "underline",
  ],
  buttons: [
    "paragraph",
    "|",
    "bold",
    "italic",

    "ul",
    "ol",

    "hr",

    "link",
    "|",
    "spellcheck",
    "speechRecognize",
    "undo",
    "redo",
    "|",
    "fullsize",
  ],
};
