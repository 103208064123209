// React imports
import React from "react";
import { useState } from "react";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

// API imports
import { useCreateCharacterCasesCaseIdCharactersPostMutation } from "../../api/jabsCentralApiPrivate";
import { DatePicker } from "@mui/x-date-pickers";

// Interface for component props
interface AddPatientFormProps {
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
  caseId: string; // The case ID for the character
}

// Component definition
const AddPatientForm: React.FC<AddPatientFormProps> = ({
  open,
  onClose,
  caseId,
}) => {
  const [createCharacter] =
    useCreateCharacterCasesCaseIdCharactersPostMutation(); // API call to create a character
  const characterType = "patient"; // The character type for the character

  // State variables for form fields
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [dob, setDob] = useState<Date | null>(null);
  const [gender, setGender] = useState("");

  // Function to reset form fields
  const resetFormFields = () => {
    setLastName("");
    setFirstName("");
    setDob(null);
    setGender("");
  };

  // Function to handle form close
  const handleClose = () => {
    resetFormFields();
    onClose();
  };

  // Function to handle form save
  const handleSave = async () => {
    const dobStr = dob?.toISOString().split("T")[0] ?? "";
    try {
      await createCharacter({
        caseId: caseId,
        character: {
          case_id: caseId,
          character_type: characterType,
          last_name: lastName,
          first_name: firstName,
          date_of_birth: dobStr,
          gender: gender,
        },
      });
      handleClose();
    } catch (error) {
      console.error("Error adding patient: ", error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
            Add Patient
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            ADD
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 610, py: 6, px: 8 }}>
        <Stack useFlexGap spacing={4}>
          <TextField
            fullWidth
            required
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            required
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <FormControl variant="outlined">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              value={gender}
              onChange={(e: SelectChangeEvent) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            label="Date of Birth"
            value={dob}
            onChange={(date) => setDob(date ?? null)}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

export default AddPatientForm;
