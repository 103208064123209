import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface updatedTag {
  selectedItem: string;
}

const initialState: updatedTag = {
  selectedItem: "",
};

const updatedTagSlice = createSlice({
  name: "updatedTag",
  initialState,
  reducers: {
    setUpdatedTag(state, action: PayloadAction<string>) {
      state.selectedItem = action.payload;
    },
    clearUpdatedTag(state) {
      state.selectedItem = "";
    },
  },
});

export const { setUpdatedTag, clearUpdatedTag } = updatedTagSlice.actions;
export default updatedTagSlice.reducer;
