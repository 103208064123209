import jwtDecode from "jwt-decode";

interface JWTClaims {
  exp: number;
  permissions?: string[];
  [key: string]: any;
}

export const getTokenPermissions = (): string[] => {
  const key = `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::${process.env.REACT_APP_AUTH0_API_AUDIENCE}::`;
  for (let i = 0; i < localStorage.length; i++) {
    const k = localStorage.key(i);
    if (k && k.startsWith(key)) {
      const tokenObject = JSON.parse(localStorage.getItem(k)!);
      const token = tokenObject.body.access_token;
      if (token) {
        try {
          const decoded = jwtDecode<JWTClaims>(token);
          return decoded.permissions || [];
        } catch (error) {
          console.error("Error decoding JWT token:", error);
          return [];
        }
      }
    }
  }
  return [];
};

export const hasPermission = (requiredPermission: string): boolean => {
  const permissions = getTokenPermissions();
  return permissions.includes(requiredPermission);
};

export const hasAnyPermission = (requiredPermissions: string[]): boolean => {
  const permissions = getTokenPermissions();
  return requiredPermissions.some((permission) =>
    permissions.includes(permission)
  );
};

export const hasAllPermissions = (requiredPermissions: string[]): boolean => {
  const permissions = getTokenPermissions();
  return requiredPermissions.every((permission) =>
    permissions.includes(permission)
  );
};
