import React from "react";
import { usePermissions } from "../hooks/usePermissions";

interface PermissionGuardProps {
  requiredPermission?: string;
  requiredPermissions?: string[];
  requireAll?: boolean;
  fallback?: React.ReactNode;
  children: React.ReactNode;
}

export const PermissionGuard: React.FC<PermissionGuardProps> = ({
  requiredPermission,
  requiredPermissions,
  requireAll = false,
  fallback = null,
  children,
}) => {
  const { checkPermission, checkAnyPermission, checkAllPermissions } =
    usePermissions();

  const hasAccess = React.useMemo(() => {
    if (requiredPermission) {
      return checkPermission(requiredPermission);
    }
    if (requiredPermissions) {
      return requireAll
        ? checkAllPermissions(requiredPermissions)
        : checkAnyPermission(requiredPermissions);
    }
    return true;
  }, [
    requiredPermission,
    requiredPermissions,
    requireAll,
    checkPermission,
    checkAnyPermission,
    checkAllPermissions,
  ]);

  if (!hasAccess) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
