// React imports
import React from "react";
import { useState, useRef } from "react";

// Redux imports
import { useDispatch } from "react-redux";
import { setUpdatedCases } from "../../redux/slices/updatedCasesSlice";

// MUI imports
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// API imports
import {
  Case,
  useUpdateCaseCasesCaseIdPutMutation,
} from "../../api/jabsCentralApiPrivate";
import { parseISO } from "date-fns";

//  component imports
import DeleteCaseButton from "./DeleteCaseButton";
import JoditEditor from "jodit-react";
import { wysiwygConfig } from "../textEditor/wysiwygConfig";
import htmlToMarkdown from "../../utils/htmlToMarkdown";
import markdownToHtmlString from "../../utils/markdownToHtmlString";

// Interface for component props
interface AddCaseFormProps {
  caseData: Case;
  open: boolean; // Indicates if the form is open
  onClose: () => void; // Function to close the form
}

// Component definition
const EditCaseForm: React.FC<AddCaseFormProps> = ({
  open,
  onClose,
  caseData,
}) => {
  // Redux dispatch function
  const dispatch = useDispatch();
  const editor = useRef(null);

  const [updateCase] = useUpdateCaseCasesCaseIdPutMutation(); // API call to create a case

  // State variables for form fields
  const [caseTitle, setCaseTitle] = useState(caseData.title);
  const [caseType, setCaseType] = useState(caseData.case_type);
  const [caseDescription, setCaseDescription] = useState(
    markdownToHtmlString(caseData.description ?? "")
  );
  const [caseDate, setCaseDate] = useState<Date | null>(
    parseISO(caseData.start_date ?? "")
  );
  const [caseStatus, setCaseStatus] = useState<string | undefined>(
    caseData.case_status ?? undefined
  );

  // Function to handle form close
  const handleClose = () => {
    onClose();
  };

  // Function to handle form save
  const handleSave = async () => {
    const markdownDescription = htmlToMarkdown(caseDescription ?? "");
    const justDate = caseDate?.toISOString().split("T")[0] ?? "";
    try {
      await updateCase({
        caseId: caseData.id ?? "",
        case: {
          title: caseTitle,
          case_type: caseType,
          case_status: caseStatus,
          description: markdownDescription,
          start_date: justDate,
          status: caseStatus,
        },
      });
      console.log("Updated Case Data: ", {
        title: caseTitle,
        case_type: caseType,
        case_status: caseStatus,
        description: markdownDescription,
        start_date: justDate,
        status: caseStatus,
      });
      dispatch(setUpdatedCases(JSON.stringify(new Date()))); // Update the updatedCases state to trigger a refetch
      handleClose();
    } catch (error) {
      console.error("Error adding case: ", error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, px: 6 }}>
            Edit Case
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Button onClick={handleClose} color="inherit" sx={{ mr: 2 }}>
            CANCEL
          </Button>
          <Button color="secondary" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 610, py: 6, px: 8 }}>
        <Stack useFlexGap spacing={4}>
          <TextField
            label="Title"
            variant="outlined"
            value={caseTitle}
            onChange={(e) => setCaseTitle(e.target.value)}
          />
          <TextField
            label="Case Type"
            variant="outlined"
            value={caseType}
            onChange={(e) => setCaseType(e.target.value)}
          />

          <DatePicker
            label="Case Date"
            value={caseDate}
            onChange={(date) => setCaseDate(date ?? null)}
          />
          <FormControl variant="outlined">
            <InputLabel id="case-status-label">Case Status</InputLabel>
            <Select
              labelId="case-status-label"
              value={caseStatus}
              onChange={(e: SelectChangeEvent) => setCaseStatus(e.target.value)}
              label="Case Status"
            >
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="closed">Closed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Typography
              variant="overline"
              color="GrayText"
              sx={{ lineHeight: 1, textTransform: "capitalize", ml: 3 }}
            >
              Description
            </Typography>
            <JoditEditor
              ref={editor}
              value={caseDescription || ""}
              config={wysiwygConfig}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setCaseDescription(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </Box>
          <Divider sx={{ mb: 1, mt: 6 }} />
          <DeleteCaseButton
            caseId={caseData.id ?? ""}
            title={caseData.title ?? ""}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

export default EditCaseForm;
